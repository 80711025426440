/**
 * @generated SignedSource<<eded372ace388a688789a8358ad66a61>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Appliance_getAppliance_Query$variables = {
  id: string;
};
export type Appliance_getAppliance_Query$data = {
  readonly appliance: {
    readonly id: string;
    readonly name: string;
    readonly serial: string;
    readonly tags: ReadonlyArray<string>;
    readonly device: {
      readonly deviceId: string;
      readonly realm: string;
      readonly baseApiUrl: string;
      readonly online: boolean;
      readonly availableApplications: ReadonlyArray<{
        readonly id: string;
        readonly protocol: string;
        readonly displayName: string;
        readonly authToken: string;
        readonly sourceUrl: string;
      }> | null;
      readonly " $fragmentSpreads": FragmentRefs<"Appliance_provisioningStatus">;
    };
    readonly assignee: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"ClientAssignee_applianceAssignee">;
  } | null;
};
export type Appliance_getAppliance_Query = {
  variables: Appliance_getAppliance_Query$variables;
  response: Appliance_getAppliance_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serial",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tags",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "realm",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "baseApiUrl",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "online",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Application",
  "kind": "LinkedField",
  "name": "availableApplications",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "protocol",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "authToken",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sourceUrl",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "assignee",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Appliance_getAppliance_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Appliance",
        "kind": "LinkedField",
        "name": "appliance",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "device",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Appliance_provisioningStatus"
              },
              (v10/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ClientAssignee_applianceAssignee"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Appliance_getAppliance_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Appliance",
        "kind": "LinkedField",
        "name": "appliance",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "device",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProvisioningStatus",
                "kind": "LinkedField",
                "name": "provisioningStatus",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "osCodeId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "osVersion",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v10/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b4348679e00e7e1eccd372b67cc78dd0",
    "id": null,
    "metadata": {},
    "name": "Appliance_getAppliance_Query",
    "operationKind": "query",
    "text": "query Appliance_getAppliance_Query(\n  $id: ID!\n) {\n  appliance(id: $id) {\n    id\n    name\n    serial\n    tags\n    device {\n      deviceId\n      realm\n      baseApiUrl\n      online\n      ...Appliance_provisioningStatus\n      availableApplications {\n        id\n        protocol\n        displayName\n        authToken\n        sourceUrl\n      }\n      id\n    }\n    assignee {\n      id\n      name\n    }\n    ...ClientAssignee_applianceAssignee\n  }\n}\n\nfragment Appliance_provisioningStatus on Device {\n  provisioningStatus {\n    osCodeId\n    osVersion\n  }\n}\n\nfragment ClientAssignee_applianceAssignee on Appliance {\n  id\n  assignee {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "ff3f7c2ac26486807ccd90353cccc7c1";

export default node;
