import React, { Suspense, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import graphql from "babel-plugin-relay/macro";
import {
  useLazyLoadQuery,
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import * as images from "assets/images";
import type { Clients_getClients_Query } from "api/__generated__/Clients_getClients_Query.graphql";
import type { Clients_getViewer_Query } from "api/__generated__/Clients_getViewer_Query.graphql";
import type Organization from "api/Organization";
import Button from "components/Button";
import Center from "components/Center";
import ConfirmModal from "components/ConfirmModal";
import ClientsTable from "components/ClientsTable";
import ErrorBoundary from "components/ErrorBoundary";
import Icon from "components/Icon";
import Image from "components/Image";
import Page, {
  PageLoading,
  PageLoadingError,
  PageToolbar,
} from "components/Page";
import ResourceCounter from "components/ResourceCounter";
import Result from "components/Result";
import SearchBox from "components/SearchBox";
import { SidebarContent } from "components/Sidebar";
import Spinner from "components/Spinner";
import Stack from "components/Stack";
import { Link, Route } from "Navigation";

/* TODO use clipboard API
 * Right now the 'clipboard-write' is supported
 * only on chromium browser.
 * document.execCommand("copy") is deprecated but
 * for now it's the only reliable way to copy to clipboard
 */
const copyInputToClipboard = (nodeSelector: string) => {
  const node = document.querySelector<HTMLInputElement>(nodeSelector);
  if (node == null) {
    return;
  }
  const selection = window.getSelection();
  if (selection == null) {
    return;
  }
  if (selection.rangeCount > 0) {
    selection.removeAllRanges();
  }
  node.focus();
  node.select();
  document.execCommand("copy");
};

const GET_VIEWER_QUERY = graphql`
  query Clients_getViewer_Query {
    viewer {
      organization {
        referralCode
      }
    }
  }
`;

const ReferralCodeModal = () => {
  const viewerData = useLazyLoadQuery<Clients_getViewer_Query>(
    GET_VIEWER_QUERY,
    {},
    { fetchPolicy: "network-only" }
  );

  return (
    <React.Fragment>
      <p>
        <FormattedMessage
          id="pages.Clients.referralCodeModal.message"
          defaultMessage="Here is your organization's referral code. Other organizations can use it to add yours as their client."
          description="Message body for the modal to show the generated referral code"
        />
      </p>
      <InputGroup>
        <Form.Control
          id="referralCode"
          value={viewerData.viewer?.organization.referralCode}
        />
        <InputGroup.Text
          role="button"
          onClick={() => copyInputToClipboard("#referralCode")}
        >
          <Icon icon="copyPaste" />
        </InputGroup.Text>
      </InputGroup>
    </React.Fragment>
  );
};

const GET_CLIENTS_QUERY = graphql`
  query Clients_getClients_Query {
    clients {
      id
      name
    }
  }
`;

type ClientsSidebarProps = {
  clients?: Clients_getClients_Query["response"]["clients"];
};

const ClientsSidebar = ({ clients = [] }: ClientsSidebarProps) => {
  const [showReferralCodeModal, setShowReferralCodeModal] = useState(false);

  return (
    <Stack gap={3} className="mt-3 p-3">
      <Image src={images.clients} />
      <ResourceCounter
        resource={
          <FormattedMessage
            id="pages.Clients.clientsCounter.resource"
            defaultMessage="{count, plural, =0 {Clients} one {Client} other {Clients}}"
            values={{ count: clients.length }}
          />
        }
        count={clients.length}
      />
      <hr />
      <Center>
        <Button onClick={() => setShowReferralCodeModal(true)}>
          <FormattedMessage
            id="pages.Clients.showReferralCodeButton"
            defaultMessage="Generate referral code"
            description="Title for the button to generate a referral code"
          />
        </Button>
      </Center>
      {showReferralCodeModal && (
        <ConfirmModal
          title={
            <FormattedMessage
              id="pages.Clients.referralCodeModal.title"
              defaultMessage="Referral Code"
              description="Title for the modal to show the generated referral code"
            />
          }
          confirmLabel={
            <FormattedMessage
              id="pages.Clients.referralCodeModal.confirmButton"
              defaultMessage="OK"
              description="Title for the button to confirm the referral code modal"
            />
          }
          onConfirm={() => setShowReferralCodeModal(false)}
        >
          <ErrorBoundary
            FallbackComponent={() => (
              <p>
                <FormattedMessage
                  id="pages.Clients.referralCodeModal.errorMessage"
                  defaultMessage="Could not generate the referral code. Please try again."
                  description="Error message for the modal to show the generated referral code"
                />
              </p>
            )}
          >
            <Suspense
              fallback={
                <div className="text-center">
                  <Spinner />
                </div>
              }
            >
              <ReferralCodeModal />
            </Suspense>
          </ErrorBoundary>
        </ConfirmModal>
      )}
    </Stack>
  );
};

interface ClientsContentProps {
  getClientsQuery: PreloadedQuery<Clients_getClients_Query>;
}

const ClientsContent = ({ getClientsQuery }: ClientsContentProps) => {
  const [searchText, setSearchText] = useState("");
  const clientsData = usePreloadedQuery(GET_CLIENTS_QUERY, getClientsQuery);

  // TODO: handle readonly type without mapping to mutable type
  const clients = useMemo(
    () =>
      clientsData.clients.map((client) => ({
        id: client.id,
        name: client.name,
      })),
    [clientsData]
  );

  if (clients.length === 0) {
    return (
      <>
        <Result.Empty
          title={
            <FormattedMessage
              id="pages.Clients.noClients.title"
              defaultMessage="There are no clients yet."
            />
          }
        >
          <Link route={Route.clientsNew}>
            <FormattedMessage
              id="pages.Clients.noClients.message"
              defaultMessage="Add a client"
            />
          </Link>
        </Result.Empty>
        <SidebarContent>
          <ClientsSidebar />
        </SidebarContent>
      </>
    );
  }

  return (
    <>
      <PageToolbar>
        <SearchBox value={searchText} onChange={setSearchText} />
      </PageToolbar>
      <ClientsTable data={clients as Organization[]} searchText={searchText} />
      <SidebarContent>
        <ClientsSidebar clients={clients} />
      </SidebarContent>
    </>
  );
};

const Clients = () => {
  const [
    getClientsQuery,
    getClients,
  ] = useQueryLoader<Clients_getClients_Query>(GET_CLIENTS_QUERY);

  useEffect(() => {
    getClients({});
  }, [getClients]);

  return (
    <Page
      title={
        <FormattedMessage
          id="pages.Clients.title"
          defaultMessage="Client List"
          description="Title for the Clients page"
        />
      }
    >
      <Suspense
        fallback={
          <>
            <PageLoading />
            <SidebarContent>
              <ClientsSidebar />
            </SidebarContent>
          </>
        }
      >
        <ErrorBoundary
          FallbackComponent={(props) => (
            <>
              <PageLoadingError onRetry={props.resetErrorBoundary} />
              <SidebarContent>
                <ClientsSidebar />
              </SidebarContent>
            </>
          )}
          onReset={() => {
            getClients({});
          }}
        >
          {getClientsQuery && (
            <ClientsContent getClientsQuery={getClientsQuery} />
          )}
        </ErrorBoundary>
      </Suspense>
    </Page>
  );
};

export default Clients;
