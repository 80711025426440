import { FormattedMessage } from "react-intl";
import Modal from "react-bootstrap/Modal";

// TODO fill in with legal text from the legal team
const LegalText = () => <div>Cookie policy goes here</div>;

type Props = {
  onClose: () => void;
};

const CookiePolicyModal = ({ onClose }: Props): React.ReactElement => {
  return (
    <Modal show centered size="lg" onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage
            id="cookiePolicy.modal.title"
            defaultMessage="Cookie Policy"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <LegalText />
      </Modal.Body>
    </Modal>
  );
};

export default CookiePolicyModal;
