import { useCallback } from "react";
import { generatePath as routerGeneratePath, matchPath } from "react-router";
import { useNavigate as useRouterNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import type { LinkProps as RouterLinkProps } from "react-router-dom";

enum Route {
  appliances = "/appliances",
  appliancesNew = "/appliances/new",
  appliancesEdit = "/appliances/:applianceId/edit",
  devicesClaim = "/appliances/claim",
  clients = "/clients",
  clientsNew = "/clients/add",
  clientsEdit = "/clients/:clientId/edit",
  users = "/users",
  usersEdit = "/users/:userId/edit",
  usersInvite = "/users/invite",
  roles = "/roles",
  rolesNew = "/roles/new",
  rolesEdit = "/roles/:roleId/edit",
  profile = "/profile",
  confirmEmail = "/confirm-email",
  login = "/login",
  logout = "/logout",
  register = "/register",
  registerWithInvite = "/register-with-invite",
  forgotPassword = "/forgot-password",
  resetPassword = "/reset-password",
}

const matchPaths = (routes: Route | Route[], path: string) => {
  const r = Array.isArray(routes) ? routes : [routes];
  return r.some((route: Route) => matchPath(route, path) != null);
};

type ParametricRoute =
  | { route: Route.appliances }
  | { route: Route.appliancesNew }
  | { route: Route.appliancesEdit; params: { applianceId: string } }
  | { route: Route.devicesClaim }
  | { route: Route.clients }
  | { route: Route.clientsNew }
  | { route: Route.clientsEdit; params: { clientId: string } }
  | { route: Route.users }
  | { route: Route.usersEdit; params: { userId: string } }
  | { route: Route.usersInvite }
  | { route: Route.roles }
  | { route: Route.rolesNew }
  | { route: Route.rolesEdit; params: { roleId: string } }
  | { route: Route.profile }
  | { route: Route.confirmEmail; params?: { token?: string } }
  | { route: Route.login }
  | { route: Route.logout }
  | { route: Route.register }
  | { route: Route.registerWithInvite }
  | { route: Route.forgotPassword }
  | { route: Route.resetPassword; params: { email: string; token: string } };

type LinkProps = Omit<RouterLinkProps, "to"> & ParametricRoute;

const generatePath = (route: ParametricRoute): string => {
  if ("params" in route && route.params) {
    return routerGeneratePath(route.route, route.params);
  }
  return route.route;
};

const Link = (props: LinkProps) => {
  let to, forwardProps;
  if ("params" in props) {
    const { route, params, ...rest } = props;
    to = routerGeneratePath(route, params);
    forwardProps = rest;
  } else {
    const { route, ...rest } = props;
    to = route;
    forwardProps = rest;
  }

  return <RouterLink to={to} {...forwardProps} />;
};

const useNavigate = () => {
  const routerNavigate = useRouterNavigate();
  const navigate = useCallback(
    (route: ParametricRoute | string) => {
      const path = typeof route === "string" ? route : generatePath(route);
      routerNavigate(path);
    },
    [routerNavigate]
  );
  return navigate;
};

export { Link, Route, matchPaths, useNavigate };
export type { LinkProps, ParametricRoute };
