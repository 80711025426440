import React, { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Form from "react-bootstrap/Form";

interface Data {
  referralCode: string;
}

interface Props {
  className?: string;
  onChange?: (data: Data, isValid: boolean) => void;
  value: Data;
}

const ClientAddForm = ({ className = "", onChange, value }: Props) => {
  const intl = useIntl();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (event) => event.preventDefault(),
    []
  );

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const target = event.target;
      let fieldValue: string | boolean | File = target.value;
      if (target.type === "checkbox") {
        fieldValue = target.checked;
      } else if (target.type === "file" && target.files) {
        fieldValue = target.files[0];
      }
      const field = target.id;
      const newValue = { ...value, [field]: fieldValue };
      const isValid = !!target.form?.checkValidity();
      onChange && onChange(newValue, isValid);
    },
    [value, onChange]
  );

  return (
    <Form
      className={className}
      onSubmit={handleSubmit}
      data-testid="client-add-form"
    >
      <Form.Group controlId="referralCode">
        <Form.Label>
          <FormattedMessage
            id="components.ClientAddForm.referralCodeLabel"
            defaultMessage="Referral code"
            description="Label for the referral code field in the client form"
          />
        </Form.Label>
        <Form.Control
          value={value.referralCode}
          onChange={handleInputChange}
          required
          placeholder={intl.formatMessage({
            id: "components.ClientAddForm.referralCodePlaceholder",
            defaultMessage: "Type in the client's referral code",
            description:
              "Placeholder for the referral code field in the client form",
          })}
          name="referralCode"
          data-testid="client-add-form-referralCode"
        />
      </Form.Group>
    </Form>
  );
};

export default ClientAddForm;
