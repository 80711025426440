type Props = React.HTMLAttributes<HTMLDivElement>;

const Center = ({ className = "", ...restProps }: Props) => {
  const classNames = [
    "d-flex flex-column justify-content-center align-items-center",
    className,
  ];

  return <div className={classNames.join(" ")} {...restProps} />;
};

export default Center;
