import React, { useEffect, useState } from "react";
import BoostrapSpinner from "react-bootstrap/Spinner";

type BoostrapSpinnerProps = React.ComponentProps<typeof BoostrapSpinner>;

type Props = Pick<BoostrapSpinnerProps, "className" | "size" | "variant">;

const Spinner = (props: Props) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(true);
    }, 250);
    return () => clearTimeout(timeout);
  }, []);

  if (!isVisible) {
    return null;
  }

  return <BoostrapSpinner animation="border" role="status" {...props} />;
};

export default Spinner;
