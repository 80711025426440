import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import graphql from "babel-plugin-relay/macro";
import {
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import Alert from "react-bootstrap/Alert";
import _ from "lodash";

import type { Role_deleteRole_Mutation } from "api/__generated__/Role_deleteRole_Mutation.graphql";
import type { Role_updateRole_Mutation } from "api/__generated__/Role_updateRole_Mutation.graphql";
import type {
  Permission,
  RoleInput,
} from "api/__generated__/RoleCreate_createRole_Mutation.graphql";
import type { Role_getRole_Query } from "api/__generated__/Role_getRole_Query.graphql";
import * as images from "assets/images";
import { Link, Route, useNavigate } from "Navigation";
import Button from "components/Button";
import { useCanOneOf } from "components/Can";
import Center from "components/Center";
import DeleteModal from "components/DeleteModal";
import ErrorBoundary from "components/ErrorBoundary";
import Image from "components/Image";
import Page, { PageLoading, PageLoadingError } from "components/Page";
import Result from "components/Result";
import RoleForm from "components/RoleForm";
import { SidebarContent } from "components/Sidebar";
import Spinner from "components/Spinner";
import Stack from "components/Stack";
import { useViewer } from "contexts/Viewer";

const GET_ROLE_QUERY = graphql`
  query Role_getRole_Query($id: ID!) {
    role(id: $id) {
      id
      name
      permissions
    }
  }
`;

const UPDATE_ROLE_MUTATION = graphql`
  mutation Role_updateRole_Mutation($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      role {
        id
        name
        permissions
      }
    }
  }
`;

const DELETE_ROLE_MUTATION = graphql`
  mutation Role_deleteRole_Mutation($input: DeleteRoleInput!) {
    deleteRole(input: $input) {
      role {
        id
      }
    }
  }
`;

type RoleSidebarProps = {
  role?: Role_getRole_Query["response"]["role"];
  onDelete?: () => void;
};

const RoleSidebar = ({ role, onDelete }: RoleSidebarProps) => {
  const canDeleteRoles = useCanOneOf(["CAN_DELETE_ROLES"]);
  const canDeleteRole =
    canDeleteRoles && role != null && !["Admin", "Default"].includes(role.name);

  return (
    <Stack gap={3} className="mt-3 p-3">
      {role && <h4>{role.name}</h4>}
      <Image src={images.users} />
      {canDeleteRole && (
        <>
          <hr />
          <Center>
            <Button variant="danger" onClick={onDelete}>
              <FormattedMessage
                id="pages.Role.deleteRoleButton"
                defaultMessage="Delete Role"
              />
            </Button>
          </Center>
        </>
      )}
    </Stack>
  );
};

const roleToRoleInput = (
  role: {
    readonly id: string;
    readonly name: string;
    readonly permissions: readonly Permission[];
  } | null
): RoleInput => ({
  name: role?.name || "",
  permissions: role?.permissions.slice() || [],
});

interface RoleContentProps {
  getRoleQuery: PreloadedQuery<Role_getRole_Query>;
}

const RoleContent = ({ getRoleQuery }: RoleContentProps) => {
  const { roleId = "" } = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const navigate = useNavigate();
  const viewer = useViewer();
  const canUpdateRoles = useCanOneOf(["CAN_EDIT_ROLES"]);
  const data = usePreloadedQuery(GET_ROLE_QUERY, getRoleQuery);

  // TODO: handle readonly type without mapping to mutable type
  const role = useMemo(
    () =>
      data.role && {
        ...data.role,
        permissions: [...data.role.permissions],
      },
    [data.role]
  );

  const [draft, setDraft] = useState<{ role: RoleInput; isValid: boolean }>({
    role: roleToRoleInput(role),
    isValid: true,
  });

  const [updateRole, isUpdatingRole] = useMutation<Role_updateRole_Mutation>(
    UPDATE_ROLE_MUTATION
  );

  const [deleteRole, isDeletingRole] = useMutation<Role_deleteRole_Mutation>(
    DELETE_ROLE_MUTATION
  );

  const handleDeleteRole = useCallback(() => {
    deleteRole({
      variables: { input: { roleId } },
      onCompleted(data, errors) {
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          setShowDeleteModal(false);
          return setErrorFeedback(errorFeedback);
        }
        viewer.refresh();
        navigate({ route: Route.roles });
      },
      onError(error) {
        setErrorFeedback(
          <FormattedMessage
            id="pages.Role.saveErrorFeedback"
            defaultMessage="Could not delete the role, please try again."
            description="Feedback for unknown deletion error in the Role page"
          />
        );
        setShowDeleteModal(false);
      },
      updater(store) {
        // TODO: should use and update Connections instead of invalidating the entire store
        // see https://relay.dev/docs/guided-tour/list-data/updating-connections/
        store.invalidateStore();
      },
    });
  }, [deleteRole, navigate, roleId, viewer]);

  const handleUpdateRole = useCallback(() => {
    updateRole({
      variables: { input: { roleId, ...draft.role } },
      onCompleted(data, errors) {
        if (errors) {
          const updateErrorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          return setErrorFeedback(updateErrorFeedback);
        }
        viewer.refresh();
      },
      onError(error) {
        setErrorFeedback(
          <FormattedMessage
            id="pages.Role.updateRoleErrorFeedback"
            defaultMessage="Could not update the role, please try again."
            description="Feedback for unknown error while updating a role"
          />
        );
      },
    });
  }, [updateRole, roleId, draft.role, viewer]);

  const handleResetDraft = useCallback(() => {
    setDraft({
      role: roleToRoleInput(role),
      isValid: true,
    });
  }, [role]);

  useEffect(() => {
    setDraft({
      role: roleToRoleInput(role),
      isValid: true,
    });
  }, [role]);

  if (!role) {
    return (
      <>
        <Result.NotFound
          title={
            <FormattedMessage
              id="pages.role.roleNotFound.title"
              defaultMessage="Role not found."
              description="Page title for a role not found"
            />
          }
        >
          <Link route={Route.roles}>
            <FormattedMessage
              id="pages.role.roleNotFound.message"
              defaultMessage="Return to the role list"
              description="Page message for a role not found"
            />
          </Link>
        </Result.NotFound>
        <SidebarContent>
          <RoleSidebar />
        </SidebarContent>
      </>
    );
  }

  const canUpdateRole =
    canUpdateRoles && !["Admin", "Default"].includes(role.name);
  const isDirtyDraft = !_.isEqual(draft.role, roleToRoleInput(role));
  const canSubmitDraft = isDirtyDraft && draft.isValid && !isUpdatingRole;

  return (
    <Stack gap={3}>
      <Alert
        show={!!errorFeedback}
        variant="danger"
        onClose={() => setErrorFeedback(null)}
        dismissible
      >
        {errorFeedback}
      </Alert>
      <RoleForm
        readOnly={!canUpdateRole}
        value={draft.role}
        onChange={(role, isValid) => setDraft({ role, isValid })}
      />
      {canUpdateRole && (
        <Stack direction="horizontal" gap={2}>
          <Button
            disabled={!isDirtyDraft}
            onClick={handleResetDraft}
            variant="secondary"
            className="ms-auto"
          >
            <FormattedMessage
              id="pages.Role.resetFormButton"
              defaultMessage="Reset"
            />
          </Button>
          <Button disabled={!canSubmitDraft} onClick={handleUpdateRole}>
            {isUpdatingRole && <Spinner size="sm" className="me-2" />}
            <FormattedMessage
              id="pages.Role.updateRoleButton"
              defaultMessage="Update Role"
            />
          </Button>
        </Stack>
      )}
      <SidebarContent>
        <RoleSidebar role={role} onDelete={() => setShowDeleteModal(true)} />
      </SidebarContent>
      {showDeleteModal && (
        <DeleteModal
          confirmText={role.name}
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteRole}
          isDeleting={isDeletingRole}
          title={
            <FormattedMessage
              id="pages.Role.deleteModal.title"
              defaultMessage="Delete Role"
              description="Title for the confirmation modal to delete a role"
            />
          }
        >
          <p>
            <FormattedMessage
              id="pages.Role.deleteModal.description"
              defaultMessage="This action cannot be undone. This will permanently delete the role <bold>{role}</bold>."
              description="Description for the confirmation modal to delete a role"
              values={{
                role: role.name,
                bold: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
              }}
            />
          </p>
        </DeleteModal>
      )}
    </Stack>
  );
};

const Role = () => {
  const { roleId = "" } = useParams();

  const [getRoleQuery, getRole] = useQueryLoader<Role_getRole_Query>(
    GET_ROLE_QUERY
  );

  useEffect(() => getRole({ id: roleId }), [getRole, roleId]);

  return (
    <Page
      title={
        <FormattedMessage id="pages.Role.title" defaultMessage="Role Details" />
      }
    >
      <Suspense
        fallback={
          <>
            <PageLoading />
            <SidebarContent>
              <RoleSidebar />
            </SidebarContent>
          </>
        }
      >
        <ErrorBoundary
          FallbackComponent={(props) => (
            <>
              <PageLoadingError onRetry={props.resetErrorBoundary} />
              <SidebarContent>
                <RoleSidebar />
              </SidebarContent>
            </>
          )}
          onReset={() => getRole({ id: roleId })}
        >
          {getRoleQuery && <RoleContent getRoleQuery={getRoleQuery} />}
        </ErrorBoundary>
      </Suspense>
    </Page>
  );
};

export default Role;
