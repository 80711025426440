import React, { Suspense, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";

import * as images from "assets/images";
import type { Users_getUsers_Query } from "api/__generated__/Users_getUsers_Query.graphql";
import Image from "components/Image";
import ErrorBoundary from "components/ErrorBoundary";
import Page, {
  PageLoading,
  PageLoadingError,
  PageToolbar,
} from "components/Page";
import ResourceCounter from "components/ResourceCounter";
import Result from "components/Result";
import SearchBox from "components/SearchBox";
import { SidebarContent } from "components/Sidebar";
import Stack from "components/Stack";
import UsersTable from "components/UsersTable";
import { Link, Route } from "Navigation";

const GET_USERS_QUERY = graphql`
  query Users_getUsers_Query {
    users {
      id
      email
      name
      roles {
        id
        name
      }
    }
  }
`;

type UsersSidebarProps = {
  users?: Users_getUsers_Query["response"]["users"];
};

const UsersSidebar = ({ users = [] }: UsersSidebarProps) => {
  return (
    <Stack gap={3} className="mt-3 p-3">
      <Image src={images.users} />
      <ResourceCounter
        resource={
          <FormattedMessage
            id="pages.Users.usersCounter.resource"
            defaultMessage="{count, plural, =0 {Users} one {User} other {Users}}"
            values={{ count: users.length }}
          />
        }
        count={users.length}
      />
    </Stack>
  );
};

interface UsersContentProps {
  getUsersQuery: PreloadedQuery<Users_getUsers_Query>;
}

const UsersContent = ({ getUsersQuery }: UsersContentProps) => {
  const [searchText, setSearchText] = useState("");
  const data = usePreloadedQuery(GET_USERS_QUERY, getUsersQuery);
  // TODO: handle readonly users type without mapping to mutable type
  const users = data.users.map((user) => ({
    id: user.id,
    name: user.name,
    email: user.email,
    roles: user.roles.map((role) => ({ id: role.id, name: role.name })),
  }));

  if (users.length === 0) {
    return (
      <>
        <Result.Empty
          title={
            <FormattedMessage
              id="pages.Users.noUsers.title"
              defaultMessage="There are no users yet."
            />
          }
        >
          <Link route={Route.usersInvite}>
            <FormattedMessage
              id="pages.Users.noUsers.message"
              defaultMessage="Invite some users"
            />
          </Link>
        </Result.Empty>
        <SidebarContent>
          <UsersSidebar />
        </SidebarContent>
      </>
    );
  }

  return (
    <>
      <PageToolbar>
        <SearchBox value={searchText} onChange={setSearchText} />
      </PageToolbar>
      <UsersTable data={users} searchText={searchText} />
      <SidebarContent>
        <UsersSidebar users={users} />
      </SidebarContent>
    </>
  );
};

const Users = () => {
  const [getUsersQuery, getUsers] = useQueryLoader<Users_getUsers_Query>(
    GET_USERS_QUERY
  );

  useEffect(() => getUsers({}), [getUsers]);

  return (
    <Page
      title={
        <FormattedMessage
          id="pages.Users.title"
          defaultMessage="User List"
          description="Title for the Users page"
        />
      }
    >
      <Suspense
        fallback={
          <>
            <PageLoading />
            <SidebarContent>
              <UsersSidebar />
            </SidebarContent>
          </>
        }
      >
        <ErrorBoundary
          FallbackComponent={(props) => (
            <>
              <PageLoadingError onRetry={props.resetErrorBoundary} />
              <SidebarContent>
                <UsersSidebar />
              </SidebarContent>
            </>
          )}
          onReset={() => getUsers({})}
        >
          {getUsersQuery && <UsersContent getUsersQuery={getUsersQuery} />}
        </ErrorBoundary>
      </Suspense>
    </Page>
  );
};

export default Users;
