import React, {
  createContext,
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { IntlProvider } from "react-intl";
import graphql from "babel-plugin-relay/macro";
import { PreloadedQuery, usePreloadedQuery, useQueryLoader } from "react-relay";
import { ErrorBoundary } from "react-error-boundary";

import type { i18n_getViewerLanguage_Query } from "api/__generated__/i18n_getViewerLanguage_Query.graphql";
import { useViewer } from "contexts/Viewer";
import en from "./langs-compiled/en.json";
import it from "./langs-compiled/it.json";

const translationsByLanguage = { en, it };

type Language = keyof typeof translationsByLanguage;

// Borrowed from https://github.com/meikidd/iso-639-1/blob/master/src/data.js
const availableLanguages: Record<
  Language,
  { name: string; nativeName: string }
> = {
  en: {
    name: "English",
    nativeName: "English",
  },
  it: {
    name: "Italian",
    nativeName: "Italiano",
  },
};

const defaultLanguage: Language = "en";

const getDefaultLanguage = () => {
  const browserLanguage = navigator.language.slice(0, 2);
  return browserLanguage in translationsByLanguage
    ? (browserLanguage as Language)
    : defaultLanguage;
};

const GET_VIEWER_LANGUAGE_QUERY = graphql`
  query i18n_getViewerLanguage_Query {
    viewer {
      preferences {
        language
      }
    }
  }
`;

interface ViewerLanguageQueryProps {
  getViewerLanguageQuery: PreloadedQuery<i18n_getViewerLanguage_Query>;
  onChange: (language: Language) => void;
}

const ViewerLanguageQuery = ({
  getViewerLanguageQuery,
  onChange,
}: ViewerLanguageQueryProps) => {
  const viewerLanguageData = usePreloadedQuery<i18n_getViewerLanguage_Query>(
    GET_VIEWER_LANGUAGE_QUERY,
    getViewerLanguageQuery
  );

  const viewerLanguage = viewerLanguageData.viewer?.preferences.language;

  useEffect(() => {
    if (viewerLanguage) {
      onChange(viewerLanguage as Language);
    }
  }, [viewerLanguage, onChange]);

  return null;
};

type LanguageContextValue = Language;

const LanguageContext = createContext<LanguageContextValue | null>(null);

interface I18nProviderProps {
  children?: React.ReactNode;
}

const I18nProvider = ({ children }: I18nProviderProps) => {
  const [language, setLanguage] = useState<Language>(getDefaultLanguage());
  const { isAuthenticated } = useViewer();

  const [
    getViewerLanguageQuery,
    getViewerLanguage,
  ] = useQueryLoader<i18n_getViewerLanguage_Query>(GET_VIEWER_LANGUAGE_QUERY);

  const translations = useMemo(
    () =>
      translationsByLanguage[language] ||
      translationsByLanguage[defaultLanguage],
    [language]
  );

  useEffect(() => {
    if (isAuthenticated) {
      getViewerLanguage({});
    }
  }, [isAuthenticated, getViewerLanguage]);

  return (
    <IntlProvider
      messages={translations as any}
      locale={language}
      defaultLocale={defaultLanguage}
    >
      <LanguageContext.Provider value={language}>
        {isAuthenticated && getViewerLanguageQuery && (
          <ErrorBoundary fallback={<React.Fragment />}>
            <Suspense fallback={null}>
              <ViewerLanguageQuery
                getViewerLanguageQuery={getViewerLanguageQuery}
                onChange={setLanguage}
              />
            </Suspense>
          </ErrorBoundary>
        )}
        {children}
      </LanguageContext.Provider>
    </IntlProvider>
  );
};

const useLanguage = (): LanguageContextValue => {
  const languageContextValue = useContext(LanguageContext);
  if (languageContextValue == null) {
    throw new Error("LanguageContext has not been Provided");
  }
  return languageContextValue;
};

export type { Language };

export { availableLanguages, useLanguage };

export default I18nProvider;
