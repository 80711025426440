import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation } from "react-relay/hooks";
import { useLocation } from "react-router";
import graphql from "babel-plugin-relay/macro";
import Form from "react-bootstrap/Form";

import type { ConfirmEmailAuthenticated_requestEmailVerification_Mutation } from "api/__generated__/ConfirmEmailAuthenticated_requestEmailVerification_Mutation.graphql";
import type { ConfirmEmailAuthenticated_verifyEmail_Mutation } from "api/__generated__/ConfirmEmailAuthenticated_verifyEmail_Mutation.graphql";
import * as images from "assets/images";
import Button from "components/Button";
import Image from "components/Image";
import Page from "components/Page";
import Stack from "components/Stack";
import Spinner from "components/Spinner";
import { SidebarContent } from "components/Sidebar";

const RESEND_VERIFICATION_EMAIL_MUTATION = graphql`
  mutation ConfirmEmailAuthenticated_requestEmailVerification_Mutation {
    requestEmailVerification {
      email
    }
  }
`;

const VERIFY_EMAIL_MUTATION = graphql`
  mutation ConfirmEmailAuthenticated_verifyEmail_Mutation(
    $input: VerifyEmailInput!
  ) {
    verifyEmail(input: $input) {
      token
    }
  }
`;

type Feedback = {
  type: "error" | "success";
  content: React.ReactNode;
};

const ConfirmEmail = () => {
  const location = useLocation();
  const intl = useIntl();
  const [
    requestEmailVerification,
    isRequestingEmailVerification,
  ] = useMutation<ConfirmEmailAuthenticated_requestEmailVerification_Mutation>(
    RESEND_VERIFICATION_EMAIL_MUTATION
  );
  const [
    verifyEmail,
    isVerifyingEmail,
  ] = useMutation<ConfirmEmailAuthenticated_verifyEmail_Mutation>(
    VERIFY_EMAIL_MUTATION
  );
  const [feedback, setFeedback] = useState<Feedback | null>(null);
  const [emailCode, setEmailCode] = useState("");

  const handleResendVerificationEmail = useCallback(() => {
    requestEmailVerification({
      variables: {},
      onCompleted(data, errors) {
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          setFeedback({
            type: "error",
            content: errorFeedback,
          });
        }
      },
      onError(error) {
        setFeedback({
          type: "error",
          content: (
            <FormattedMessage
              id="pages.ConfirmEmail.resendVerificationEmailFeedback"
              defaultMessage="Could not send a verification email, please try again."
            />
          ),
        });
      },
    });
  }, [requestEmailVerification]);

  const handleVerifyEmail = useCallback(
    (token: string) => {
      verifyEmail({
        variables: {
          input: { token },
        },
        onCompleted(data, errors) {
          if (errors) {
            setFeedback({
              type: "error",
              content: (
                <FormattedMessage
                  id="pages.ConfirmEmail.form.invalidTokenFeedback"
                  defaultMessage="Could not verify the email address. Invalid token or expired."
                />
              ),
            });
          } else {
            setFeedback({
              type: "success",
              content: (
                <FormattedMessage
                  id="pages.ConfirmEmail.form.emailConfirmed"
                  defaultMessage="Your email address was succesfully confirmed!"
                />
              ),
            });
          }
        },
        onError(error) {
          setFeedback({
            type: "error",
            content: (
              <FormattedMessage
                id="pages.ConfirmEmail.form.emailVerificationErrorFeedback"
                defaultMessage="Could not verify the email address. Please try again in a few minutes."
              />
            ),
          });
        },
        updater(store, data) {
          const root = store.getRoot();
          const viewer = root.getLinkedRecord("viewer");
          if (viewer && data.verifyEmail?.token) {
            viewer.setValue(true, "emailVerified");
          }
        },
      });
    },
    [verifyEmail]
  );

  const handleEmailCodeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFeedback(null);
      setEmailCode(e.target.value);
    },
    []
  );

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const parameterToken = urlSearchParams.get("token") || "";

    if (parameterToken) {
      setEmailCode(parameterToken);
      handleVerifyEmail(parameterToken);
    }
  }, [location, handleVerifyEmail]);

  const emailVerificationCompleted = !!feedback && feedback.type === "success";

  return (
    <Page
      title={
        <FormattedMessage
          id="pages.ConfirmEmail.title"
          defaultMessage="Confirm Email"
        />
      }
    >
      <Form>
        <Form.Group controlId="email-code">
          <Form.Label>
            <FormattedMessage
              id="pages.ConfirmEmail.confirmationCodeLabel"
              defaultMessage="Confirmation code"
            />
          </Form.Label>
          <Form.Control
            name="email-code"
            value={emailCode}
            onChange={handleEmailCodeChange}
            required
            placeholder={intl.formatMessage({
              id: "pages.ConfirmEmail.codePlaceholder",
              defaultMessage: "The code sent via mail",
            })}
            isValid={emailVerificationCompleted}
            isInvalid={!!feedback && feedback.type === "error"}
          />
          {feedback && (
            <Form.Control.Feedback
              type={feedback.type === "error" ? "invalid" : "valid"}
            >
              {feedback.content}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        {!emailVerificationCompleted && (
          <>
            <Button
              className="me-2 mt-3"
              disabled={!emailCode || isVerifyingEmail}
              onClick={() => handleVerifyEmail(emailCode)}
            >
              {isVerifyingEmail && <Spinner size="sm" className="me-2" />}
              <FormattedMessage
                id="pages.ConfirmEmail.verifyEmailButton"
                defaultMessage="Verify Email"
              />
            </Button>
            <Button
              variant="secondary"
              className="mt-3"
              disabled={isVerifyingEmail || isRequestingEmailVerification}
              onClick={handleResendVerificationEmail}
            >
              {isRequestingEmailVerification && (
                <Spinner size="sm" className="me-2" />
              )}
              <FormattedMessage
                id="pages.ConfirmEmail.resendEmailButton"
                defaultMessage="Resend Verification Email"
              />
            </Button>
          </>
        )}
      </Form>

      <SidebarContent>
        <Stack gap={3} className="mt-3 p-3">
          <Image fallbackSrc={images.profile} />
        </Stack>
      </SidebarContent>
    </Page>
  );
};

export default ConfirmEmail;
