import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay/hooks";
import Alert from "react-bootstrap/Alert";

import type {
  RoleCreate_createRole_Mutation,
  RoleInput,
} from "api/__generated__/RoleCreate_createRole_Mutation.graphql";
import * as images from "assets/images";
import { Route, useNavigate } from "Navigation";
import Button from "components/Button";
import Image from "components/Image";
import Page from "components/Page";
import RoleForm from "components/RoleForm";
import { SidebarContent } from "components/Sidebar";
import Spinner from "components/Spinner";
import Stack from "components/Stack";

const CREATE_ROLE_MUTATION = graphql`
  mutation RoleCreate_createRole_Mutation($input: CreateRoleInput!) {
    createRole(input: $input) {
      role {
        id
        name
        permissions
      }
    }
  }
`;

const RoleCreateSidebar = () => {
  return (
    <Stack gap={3} className="mt-3 p-3">
      <Image src={images.users} />
    </Stack>
  );
};

const initialDraft: RoleInput = {
  name: "",
  permissions: [],
};

const RoleCreate = () => {
  const [draft, setDraft] = useState({
    role: initialDraft,
    isValid: false,
  });
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const navigate = useNavigate();
  const [
    createRole,
    isCreatingRole,
  ] = useMutation<RoleCreate_createRole_Mutation>(CREATE_ROLE_MUTATION);

  const handleCreateRole = useCallback(() => {
    createRole({
      variables: { input: { role: draft.role } },
      onCompleted(data, errors) {
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          return setErrorFeedback(errorFeedback);
        }
        const roleId = data.createRole?.role.id;
        if (roleId) {
          navigate({ route: Route.rolesEdit, params: { roleId } });
        }
      },
      onError(error) {
        setErrorFeedback(
          <FormattedMessage
            id="pages.RoleCreate.saveErrorFeedback"
            defaultMessage="Could not create the role, please try again."
            description="Feedback for unknown creation error in the RoleCreate page"
          />
        );
      },
      updater(store) {
        // TODO: should use and update Connections instead of invalidating the entire store
        // see https://relay.dev/docs/guided-tour/list-data/updating-connections/
        store.invalidateStore();
      },
    });
  }, [draft.role, createRole, navigate]);

  return (
    <Page
      title={
        <FormattedMessage
          id="pages.RoleCreate.title"
          defaultMessage="Create Role"
          description="Title for the RoleCreate page"
        />
      }
    >
      <Alert
        show={!!errorFeedback}
        variant="danger"
        onClose={() => setErrorFeedback(null)}
        dismissible
      >
        {errorFeedback}
      </Alert>
      <Stack gap={3}>
        <RoleForm
          value={draft.role}
          onChange={(role, isValid) => setDraft({ role, isValid })}
        />
        <div className="mt-3 d-flex justify-content-end align-items-center">
          <Button
            disabled={!draft.isValid || isCreatingRole}
            onClick={handleCreateRole}
          >
            {isCreatingRole && <Spinner size="sm" className="me-2" />}
            <FormattedMessage
              id="pages.RoleCreate.createRoleButton"
              defaultMessage="Create Role"
            />
          </Button>
        </div>
      </Stack>
      <SidebarContent>
        <RoleCreateSidebar />
      </SidebarContent>
    </Page>
  );
};

export default RoleCreate;
