import React, { Suspense, useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";

import * as images from "assets/images";
import type { Roles_getRoles_Query } from "api/__generated__/Roles_getRoles_Query.graphql";
import ErrorBoundary from "components/ErrorBoundary";
import Image from "components/Image";
import Page, {
  PageLoading,
  PageLoadingError,
  PageToolbar,
} from "components/Page";
import ResourceCounter from "components/ResourceCounter";
import SearchBox from "components/SearchBox";
import { SidebarContent } from "components/Sidebar";
import Result from "components/Result";
import RolesTable from "components/RolesTable";
import Stack from "components/Stack";
import { Link, Route } from "Navigation";

const GET_ROLES_QUERY = graphql`
  query Roles_getRoles_Query {
    roles {
      id
      name
    }
  }
`;

type RolesSidebarProps = {
  roles?: Roles_getRoles_Query["response"]["roles"];
};

const RolesSidebar = ({ roles = [] }: RolesSidebarProps) => {
  return (
    <Stack gap={3} className="mt-3 p-3">
      <Image src={images.users} />
      <ResourceCounter
        resource={
          <FormattedMessage
            id="pages.Roles.rolesCounter.resource"
            defaultMessage="{count, plural, =0 {Roles} one {Role} other {Roles}}"
            values={{ count: roles.length }}
          />
        }
        count={roles.length}
      />
    </Stack>
  );
};

interface RolesContentProps {
  getRolesQuery: PreloadedQuery<Roles_getRoles_Query>;
}

const RolesContent = ({ getRolesQuery }: RolesContentProps) => {
  const [searchText, setSearchText] = useState("");
  const data = usePreloadedQuery(GET_ROLES_QUERY, getRolesQuery);

  // TODO: handle readonly type without mapping to mutable type
  const roles = useMemo(
    () =>
      data.roles.map((role) => ({
        id: role.id,
        name: role.name,
      })),
    [data]
  );

  if (roles.length === 0) {
    return (
      <>
        <Result.Empty
          title={
            <FormattedMessage
              id="pages.Roles.noRoles.title"
              defaultMessage="There are no roles yet."
            />
          }
        >
          <Link route={Route.rolesNew}>
            <FormattedMessage
              id="pages.Roles.noRoles.message"
              defaultMessage="Add a role"
            />
          </Link>
        </Result.Empty>
        <SidebarContent>
          <RolesSidebar />
        </SidebarContent>
      </>
    );
  }

  return (
    <>
      <PageToolbar>
        <SearchBox value={searchText} onChange={setSearchText} />
      </PageToolbar>
      <RolesTable data={roles} searchText={searchText} />
      <SidebarContent>
        <RolesSidebar roles={roles} />
      </SidebarContent>
    </>
  );
};

const Roles = () => {
  const [getRolesQuery, getRoles] = useQueryLoader<Roles_getRoles_Query>(
    GET_ROLES_QUERY
  );

  useEffect(() => getRoles({}), [getRoles]);

  return (
    <Page
      title={
        <FormattedMessage
          id="pages.Roles.title"
          defaultMessage="Role List"
          description="Title for the Roles page"
        />
      }
    >
      <Suspense
        fallback={
          <>
            <PageLoading />
            <SidebarContent>
              <RolesSidebar />
            </SidebarContent>
          </>
        }
      >
        <ErrorBoundary
          FallbackComponent={(props) => (
            <>
              <PageLoadingError onRetry={props.resetErrorBoundary} />
              <SidebarContent>
                <RolesSidebar />
              </SidebarContent>
            </>
          )}
          onReset={() => getRoles({})}
        >
          {getRolesQuery && <RolesContent getRolesQuery={getRolesQuery} />}
        </ErrorBoundary>
      </Suspense>
    </Page>
  );
};

export default Roles;
