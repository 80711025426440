import { FormattedMessage } from "react-intl";

import { Link, Route } from "Navigation";
import Tag from "components/Tag";
import StyledTable from "components/StyledTable";
import type { Column } from "components/StyledTable";

interface Role {
  id: string;
  name: string;
}

interface User {
  id: string;
  name: string;
  roles: Role[];
  email: string;
}

interface Props {
  className?: string;
  data: User[];
  searchText?: string;
}

const columns: Column<User>[] = [
  {
    accessor: "name",
    Header: (
      <FormattedMessage
        id="components.UsersTable.nameTitle"
        defaultMessage="Name"
        description="Title for the Name column of the users table"
      />
    ),
    Cell: ({ row, value }) => (
      <Link route={Route.usersEdit} params={{ userId: row.original.id }}>
        {value}
      </Link>
    ),
  },
  {
    accessor: "roles",
    Header: (
      <FormattedMessage
        id="components.UsersTable.rolesTitle"
        defaultMessage="Roles"
        description="Title for the Roles column of the users table"
      />
    ),
    Cell: ({ row, value }) =>
      value.map((role) => (
        <Tag key={role.id} className="me-2">
          {role.name}
        </Tag>
      )),
  },
  {
    accessor: "email",
    Header: (
      <FormattedMessage
        id="components.UsersTable.emailTitle"
        defaultMessage="Email"
        description="Title for the Email column of the users table"
      />
    ),
  },
];

const UsersTable = ({ className, data, searchText }: Props) => (
  <StyledTable
    className={className}
    columns={columns}
    data={data}
    searchText={searchText}
  />
);

export default UsersTable;
