import { ComponentProps } from "react";

import "./ActionButton.scss";

type Props = ComponentProps<"button">;

const ActionButton = ({ className = "", ...restProps }: Props) => (
  <button
    type="button"
    className={
      "btn action-button bg-white border shadow-sm fw-bold " + className
    }
    {...restProps}
  />
);

export default ActionButton;
