import { useCallback } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import type { InputProps, MultiValueProps, OptionsType } from "react-select";
import _ from "lodash";

import Icon from "components/Icon";
import Tag from "components/Tag";
import "./MultiSelect.scss";

type Option = {
  label: string;
  value: string;
};

const MultiValueLabel = (props: MultiValueProps<Option>) => {
  const { data, selectProps } = props;
  return (
    <components.MultiValueLabel
      data={data}
      innerProps={{ className: "me-1" }}
      selectProps={selectProps}
    >
      {data.label}
    </components.MultiValueLabel>
  );
};

const MultiValueRemove = (props: MultiValueProps<Option>) => {
  if (props.selectProps.isDisabled) {
    return null;
  }
  return (
    <components.MultiValueRemove {...props}>
      <Icon icon="close" />
    </components.MultiValueRemove>
  );
};

const MultiValueContainer = (props: MultiValueProps<Option>) => {
  const { children, data, selectProps } = props;
  return (
    <Tag className="me-1">
      <components.MultiValueContainer
        data={data}
        innerProps={{ className: "d-flex" }}
        selectProps={selectProps}
      >
        {children}
      </components.MultiValueContainer>
    </Tag>
  );
};

const Input = (props: InputProps) => {
  const { selectProps } = props as InputProps & {
    selectProps: MultiSelectProps<unknown>;
  };
  const forwardProps = _.pick(selectProps, ["data-testid"]);
  return <components.Input {...props} {...forwardProps} />;
};

const customComponents = {
  Input,
  MultiValueContainer,
  MultiValueLabel,
  MultiValueRemove,
};

type MultiSelectProps<Value> = {
  id?: string;
  name?: string;
  getValueId: (value: Value) => string;
  getValueLabel: (value: Value) => string;
  selected: Value[];
  values: Value[];
  onChange: (selected: Value[]) => void;
  onCreateValue?: (text: string) => any;
  disabled?: boolean;
  loading?: boolean;
  required?: boolean;
  placeholder?: string;
};

const MultiSelect = <Value extends any>({
  id,
  name,
  getValueId,
  getValueLabel,
  selected,
  values,
  onChange,
  onCreateValue,
  disabled = false,
  loading = false,
  required = false,
  placeholder,
  ...restProps
}: MultiSelectProps<Value>) => {
  const toOption = useCallback(
    (value: Value): Option => ({
      label: getValueLabel(value),
      value: getValueId(value),
    }),
    [getValueLabel, getValueId]
  );

  const toValue = useCallback(
    (option: Option): Value =>
      values.find((value) => getValueId(value) === option.value)!,
    [values, getValueId]
  );

  const handleChange = useCallback(
    (options: OptionsType<Option>) => {
      onChange && onChange(options.map(toValue));
    },
    [onChange, toValue]
  );

  const isInvalid = required && selected.length === 0;
  const selectClassNamePrefix = "multi-select";
  let selectClassName = selectClassNamePrefix;
  if (isInvalid) {
    selectClassName += " invalid";
  }

  const props = {
    name,
    inputId: id,
    components: customComponents,
    isClearable: true,
    isMulti: true,
    placeholder,
    onChange: handleChange,
    onCreateOption: onCreateValue,
    value: selected.map(toOption),
    options: values.map(toOption),
    isDisabled: disabled,
    isLoading: loading,
    // use React Portals to fixed overlapping issues with other React component. See:
    // https://github.com/JedWatson/react-select/issues/1085
    // https://github.com/JedWatson/react-select/issues/1537
    menuPortalTarget: document.body,
    classNamePrefix: selectClassNamePrefix,
    className: selectClassName,
    ...restProps,
  } as const;

  const isCreatable = !!onCreateValue;

  if (disabled) {
    return (
      <div id={id} {...restProps}>
        {props.value.map((option) => (
          <Tag className="mb-1 me-1" key={option.value}>
            {option.label}
          </Tag>
        ))}
      </div>
    );
  }

  if (isCreatable) {
    return <CreatableSelect {...props} />;
  }

  return <Select {...props} />;
};

export default MultiSelect;
