/**
 * @generated SignedSource<<b0f0118ad5b8392067cccf65877adf8d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Clients_getViewer_Query$variables = {};
export type Clients_getViewer_Query$data = {
  readonly viewer: {
    readonly organization: {
      readonly referralCode: string;
    };
  } | null;
};
export type Clients_getViewer_Query = {
  variables: Clients_getViewer_Query$variables;
  response: Clients_getViewer_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "referralCode",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Clients_getViewer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Clients_getViewer_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9c9e6c1b89eab9ecc7ac2dbaef7fcd09",
    "id": null,
    "metadata": {},
    "name": "Clients_getViewer_Query",
    "operationKind": "query",
    "text": "query Clients_getViewer_Query {\n  viewer {\n    organization {\n      referralCode\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "252946c84b97ee0321bfce6cd03f0a1b";

export default node;
