import { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useMutation } from "react-relay/hooks";
import { useLocation } from "react-router";
import graphql from "babel-plugin-relay/macro";
import Form from "react-bootstrap/Form";

import type { ConfirmEmail_verifyEmail_Mutation } from "api/__generated__/ConfirmEmail_verifyEmail_Mutation.graphql";
import { Link, Route } from "Navigation";
import AuthPage from "components/AuthPage";
import Button from "components/Button";
import Center from "components/Center";
import Stack from "components/Stack";
import Spinner from "components/Spinner";

const VERIFY_EMAIL_MUTATION = graphql`
  mutation ConfirmEmail_verifyEmail_Mutation($input: VerifyEmailInput!) {
    verifyEmail(input: $input) {
      token
    }
  }
`;

type Feedback = {
  type: "error" | "success";
  content: React.ReactNode;
};

const ConfirmEmail = () => {
  const location = useLocation();
  const intl = useIntl();
  const [
    verifyEmail,
    isVerifyingEmail,
  ] = useMutation<ConfirmEmail_verifyEmail_Mutation>(VERIFY_EMAIL_MUTATION);
  const [feedback, setFeedback] = useState<Feedback | null>(null);
  const [emailCode, setEmailCode] = useState("");

  const handleVerifyEmail = useCallback(
    (token: string) => {
      verifyEmail({
        variables: {
          input: { token },
        },
        onCompleted(data, errors) {
          if (errors) {
            setFeedback({
              type: "error",
              content: (
                <FormattedMessage
                  id="pages.ConfirmEmail.form.invalidTokenFeedback"
                  defaultMessage="Could not verify the email address. Invalid token or expired."
                />
              ),
            });
          } else {
            setFeedback({
              type: "success",
              content: (
                <FormattedMessage
                  id="pages.ConfirmEmail.form.emailConfirmed"
                  defaultMessage="Your email address was succesfully confirmed!"
                />
              ),
            });
          }
        },
        onError(error) {
          setFeedback({
            type: "error",
            content: (
              <FormattedMessage
                id="pages.ConfirmEmail.form.emailVerificationErrorFeedback"
                defaultMessage="Could not verify the email address. Please try again in a few minutes."
              />
            ),
          });
        },
      });
    },
    [verifyEmail]
  );

  const handleEmailCodeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFeedback(null);
      setEmailCode(e.target.value);
    },
    []
  );

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const parameterToken = urlSearchParams.get("token") || "";

    if (parameterToken) {
      setEmailCode(parameterToken);
      handleVerifyEmail(parameterToken);
    }
  }, [location, handleVerifyEmail]);

  const emailVerificationCompleted = !!feedback && feedback.type === "success";

  return (
    <AuthPage>
      <Form>
        <Stack gap={3}>
          <Form.Group controlId="email-code">
            <Form.Label>
              <FormattedMessage
                id="pages.ConfirmEmail.confirmationCodeLabel"
                defaultMessage="Confirmation code"
              />
            </Form.Label>
            <Form.Control
              name="email-code"
              value={emailCode}
              onChange={handleEmailCodeChange}
              required
              placeholder={intl.formatMessage({
                id: "pages.ConfirmEmail.codePlaceholder",
                defaultMessage: "The code sent via mail",
              })}
              isValid={emailVerificationCompleted}
              isInvalid={!!feedback && feedback.type === "error"}
            />
            {feedback && (
              <Form.Control.Feedback
                type={feedback.type === "error" ? "invalid" : "valid"}
              >
                {feedback.content}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          {!emailVerificationCompleted && (
            <Button
              className="me-2 mt-3"
              disabled={!emailCode || isVerifyingEmail}
              onClick={() => handleVerifyEmail(emailCode)}
            >
              {isVerifyingEmail && <Spinner size="sm" className="me-2" />}
              <FormattedMessage
                id="pages.ConfirmEmail.verifyEmailButton"
                defaultMessage="Verify Email"
              />
            </Button>
          )}
          <Center>
            <span>
              <FormattedMessage
                id="pages.ConfirmEmail.loginLink"
                defaultMessage="Click here to return to the <loginLink>login page</loginLink>"
                values={{
                  loginLink: (chunks: React.ReactNode) => (
                    <Link route={Route.login}>{chunks}</Link>
                  ),
                }}
              />
            </span>
          </Center>
        </Stack>
      </Form>
    </AuthPage>
  );
};

export default ConfirmEmail;
