/**
 * @generated SignedSource<<a56fe7d86a33392cecceed8c9060f7c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateApplianceInput = {
  applianceId: string;
  name?: string | null;
  tags?: ReadonlyArray<string> | null;
};
export type Appliance_updateAppliance_Mutation$variables = {
  input: UpdateApplianceInput;
};
export type Appliance_updateAppliance_Mutation$data = {
  readonly updateAppliance: {
    readonly appliance: {
      readonly id: string;
      readonly name: string;
      readonly tags: ReadonlyArray<string>;
    };
  } | null;
};
export type Appliance_updateAppliance_Mutation = {
  variables: Appliance_updateAppliance_Mutation$variables;
  response: Appliance_updateAppliance_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateAppliancePayload",
    "kind": "LinkedField",
    "name": "updateAppliance",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Appliance",
        "kind": "LinkedField",
        "name": "appliance",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "tags",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Appliance_updateAppliance_Mutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Appliance_updateAppliance_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c6843c8785b27b7da2d936c9a1ad3075",
    "id": null,
    "metadata": {},
    "name": "Appliance_updateAppliance_Mutation",
    "operationKind": "mutation",
    "text": "mutation Appliance_updateAppliance_Mutation(\n  $input: UpdateApplianceInput!\n) {\n  updateAppliance(input: $input) {\n    appliance {\n      id\n      name\n      tags\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "07b4e1978a295d0e00cd50a18b766bc2";

export default node;
