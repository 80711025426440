import React, { useCallback, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import type {
  RoleInput,
  Permission,
} from "api/__generated__/RoleCreate_createRole_Mutation.graphql";
import CheckboxTree, { Tree } from "components/CheckboxTree";
import Stack from "components/Stack";

const appliancesPermissionTrees: Tree<Permission>[] = [
  {
    id: "appliances",
    label: (
      <FormattedMessage
        id="components.RoleForm.allAppliancesPermissions"
        defaultMessage="All permissions"
      />
    ),
    children: [
      {
        value: "CAN_CREATE_APPLIANCES",
        label: (
          <FormattedMessage
            id="components.RoleForm.canCreateAppliancesPermission"
            defaultMessage="Can add Appliances"
            description="Label for the permission to create appliances"
          />
        ),
      },
      {
        value: "CAN_LIST_APPLIANCES",
        label: (
          <FormattedMessage
            id="components.RoleForm.canListAppliancesPermission"
            defaultMessage="Can see Appliance List"
            description="Label for the permission to list appliances"
          />
        ),
      },
      {
        value: "CAN_GET_APPLIANCES",
        label: (
          <FormattedMessage
            id="components.RoleForm.canGetAppliancesPermission"
            defaultMessage="Can see specific Appliances"
            description="Label for the permission to see specific appliances"
          />
        ),
      },
      {
        value: "CAN_EDIT_APPLIANCES",
        label: (
          <FormattedMessage
            id="components.RoleForm.canEditAppliancesPermission"
            defaultMessage="Can edit info for Appliances"
            description="Label for the permission to edit appliances"
          />
        ),
      },
    ],
  },
];

const clientsPermissionTrees: Tree<Permission>[] = [
  {
    id: "clients",
    label: (
      <FormattedMessage
        id="components.RoleForm.allClientsPermissions"
        defaultMessage="All permissions"
      />
    ),
    children: [
      {
        value: "CAN_LIST_CLIENTS",
        label: (
          <FormattedMessage
            id="components.RoleForm.canListClientsPermission"
            defaultMessage="Can see Client List"
            description="Label for the permission to list clients"
          />
        ),
      },
      {
        value: "CAN_ADD_CLIENTS",
        label: (
          <FormattedMessage
            id="components.RoleForm.canAddClientsPermission"
            defaultMessage="Can add Clients"
            description="Label for the permission to add clients"
          />
        ),
      },
      {
        value: "CAN_REMOVE_CLIENTS",
        label: (
          <FormattedMessage
            id="components.RoleForm.canRemoveClientsPermission"
            defaultMessage="Can remove Clients"
            description="Label for the permission to remove clients"
          />
        ),
      },
      {
        value: "CAN_ASSIGN_APPLIANCES",
        label: (
          <FormattedMessage
            id="components.RoleForm.canAssignAppliancesPermission"
            defaultMessage="Can assign Appliances to Clients"
            description="Label for the permission to assign appliances to clients"
          />
        ),
      },
    ],
  },
];

const rolesPermissionTrees: Tree<Permission>[] = [
  {
    id: "roles",
    label: (
      <FormattedMessage
        id="components.RoleForm.allRolesPermissions"
        defaultMessage="All permissions"
      />
    ),
    children: [
      {
        value: "CAN_LIST_ROLES",
        label: (
          <FormattedMessage
            id="components.RoleForm.canListRolesPermission"
            defaultMessage="Can see Role List"
            description="Label for the permission to list roles"
          />
        ),
      },
      {
        value: "CAN_GET_ROLES",
        label: (
          <FormattedMessage
            id="components.RoleForm.canGetRolesPermission"
            defaultMessage="Can see specific Roles"
            description="Label for the permission to see specific roles"
          />
        ),
      },
      {
        value: "CAN_CREATE_ROLES",
        label: (
          <FormattedMessage
            id="components.RoleForm.canCreateRolesPermission"
            defaultMessage="Can create Roles"
            description="Label for the permission to create roles"
          />
        ),
      },
      {
        value: "CAN_EDIT_ROLES",
        label: (
          <FormattedMessage
            id="components.RoleForm.canEditRolesPermission"
            defaultMessage="Can edit Roles"
            description="Label for the permission to edit roles"
          />
        ),
      },
      {
        value: "CAN_DELETE_ROLES",
        label: (
          <FormattedMessage
            id="components.RoleForm.canDeleteRolesPermission"
            defaultMessage="Can delete Roles"
            description="Label for the permission to delete roles"
          />
        ),
      },
    ],
  },
];

const usersPermissionTrees: Tree<Permission>[] = [
  {
    id: "users",
    label: (
      <FormattedMessage
        id="components.RoleForm.allUsersPermissions"
        defaultMessage="All permissions"
      />
    ),
    children: [
      {
        value: "CAN_LIST_USERS",
        label: (
          <FormattedMessage
            id="components.RoleForm.canListUsersPermission"
            defaultMessage="Can see User List"
            description="Label for the permission to list users"
          />
        ),
      },
      {
        value: "CAN_GET_USERS",
        label: (
          <FormattedMessage
            id="components.RoleForm.canGetUsersPermission"
            defaultMessage="Can see specific Users"
            description="Label for the permission to see specific users"
          />
        ),
      },
      {
        value: "CAN_MANAGE_USER_ROLES",
        label: (
          <FormattedMessage
            id="components.RoleForm.canManageUsersRolesPermission"
            defaultMessage="Can manage Users Roles"
            description="Label for the permission to manage users roles"
          />
        ),
      },
      {
        value: "CAN_INVITE_USERS",
        label: (
          <FormattedMessage
            id="components.RoleForm.canInviteUsersPermission"
            defaultMessage="Can invite Users"
            description="Label for the permission to invite users"
          />
        ),
      },
      {
        value: "CAN_DELETE_USERS",
        label: (
          <FormattedMessage
            id="components.RoleForm.canDeleteUsersPermission"
            defaultMessage="Can delete Users"
            description="Label for the permission to delete users"
          />
        ),
      },
    ],
  },
];

interface Props {
  className?: string;
  readOnly?: boolean;
  value: RoleInput;
  onChange?: (data: RoleInput, isValid: boolean) => void;
}

const UserForm = ({
  className = "",
  readOnly = false,
  value,
  onChange,
}: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const intl = useIntl();

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const target = event.target;
      const fieldValue =
        target.type === "checkbox" ? target.checked : target.value;
      const field = target.id;
      const newValue = { ...value, [field]: fieldValue };
      const isFormValid = !!formRef.current?.checkValidity();
      onChange && onChange(newValue, isFormValid);
    },
    [value, onChange]
  );

  const handlePermissionsChange = useCallback(
    (permissions: Permission[]) => {
      const newValue = { ...value, permissions };
      const isFormValid = !!formRef.current?.checkValidity();
      onChange && onChange(newValue, isFormValid);
    },
    [value, onChange]
  );

  return (
    <Form ref={formRef} className={className} data-testid="role-form">
      <Stack gap={3}>
        <Form.Group controlId="name">
          <Form.Label>
            <FormattedMessage
              id="components.RoleForm.nameLabel"
              defaultMessage="Name"
              description="Label for the name field in the role form"
            />
          </Form.Label>
          <Form.Control
            value={value.name}
            onChange={handleInputChange}
            readOnly={readOnly}
            plaintext={readOnly}
            required
            placeholder={intl.formatMessage({
              id: "components.RoleForm.namePlaceholder",
              defaultMessage: "Type in the role name",
              description: "Placeholder for the name field in the role form",
            })}
            name="name"
            data-testid="role-form-name"
          />
        </Form.Group>
        <Form.Group controlId="permissions" data-testid="role-form-permissions">
          <Form.Label>
            <FormattedMessage
              id="components.RoleForm.permissionsLabel"
              defaultMessage="Permissions"
              description="Label for the permissions field in the role form"
            />
          </Form.Label>
          <Row xs={1} lg={2} xl={3} xxl={4} className="g-4 pt-1">
            <Col>
              <Card className="h-100 border-0 shadow-sm p-4">
                <Stack gap={3}>
                  <h6 className="text-primary">
                    <FormattedMessage
                      id="components.RoleForm.appliancesPermissionsTitle"
                      defaultMessage="Appliances"
                    />
                  </h6>
                  <CheckboxTree
                    trees={appliancesPermissionTrees}
                    checked={value.permissions}
                    onChange={handlePermissionsChange}
                    readOnly={readOnly}
                    alwaysExpanded
                  />
                </Stack>
              </Card>
            </Col>
            <Col>
              <Card className="h-100 border-0 shadow-sm p-4">
                <Stack gap={3}>
                  <h6 className="text-primary">
                    <FormattedMessage
                      id="components.RoleForm.clientsPermissionsTitle"
                      defaultMessage="Clients"
                    />
                  </h6>
                  <CheckboxTree
                    trees={clientsPermissionTrees}
                    checked={value.permissions}
                    onChange={handlePermissionsChange}
                    readOnly={readOnly}
                    alwaysExpanded
                  />
                </Stack>
              </Card>
            </Col>
            <Col>
              <Card className="h-100 border-0 shadow-sm p-4">
                <Stack gap={3}>
                  <h6 className="text-primary">
                    <FormattedMessage
                      id="components.RoleForm.rolesPermissionsTitle"
                      defaultMessage="Roles"
                    />
                  </h6>
                  <CheckboxTree
                    trees={rolesPermissionTrees}
                    checked={value.permissions}
                    onChange={handlePermissionsChange}
                    readOnly={readOnly}
                    alwaysExpanded
                  />
                </Stack>
              </Card>
            </Col>
            <Col>
              <Card className="h-100 border-0 shadow-sm p-4">
                <Stack gap={3}>
                  <h6 className="text-primary">
                    <FormattedMessage
                      id="components.RoleForm.usersPermissionsTitle"
                      defaultMessage="Users"
                    />
                  </h6>
                  <CheckboxTree
                    trees={usersPermissionTrees}
                    checked={value.permissions}
                    onChange={handlePermissionsChange}
                    readOnly={readOnly}
                    alwaysExpanded
                  />
                </Stack>
              </Card>
            </Col>
          </Row>
        </Form.Group>
      </Stack>
    </Form>
  );
};

export default UserForm;
