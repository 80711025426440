/**
 * @generated SignedSource<<b81d0e8990b7412641db615a9af03f94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Clients_getClients_Query$variables = {};
export type Clients_getClients_Query$data = {
  readonly clients: ReadonlyArray<{
    readonly id: string;
    readonly name: string;
  }>;
};
export type Clients_getClients_Query = {
  variables: Clients_getClients_Query$variables;
  response: Clients_getClients_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Organization",
    "kind": "LinkedField",
    "name": "clients",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Clients_getClients_Query",
    "selections": (v0/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Clients_getClients_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "18d963d06c9e9c0aaa2051f1b5cbb3b9",
    "id": null,
    "metadata": {},
    "name": "Clients_getClients_Query",
    "operationKind": "query",
    "text": "query Clients_getClients_Query {\n  clients {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "34f66e8478926b3140cff0d93e03d807";

export default node;
