/**
 * @generated SignedSource<<147520a01022e9f65711b39a831aaf92>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateUserPreferencesInput = {
  preferences: UserPreferencesInput;
};
export type UserPreferencesInput = {
  language?: string | null;
};
export type Profile_updateUserPreferences_Mutation$variables = {
  input: UpdateUserPreferencesInput;
};
export type Profile_updateUserPreferences_Mutation$data = {
  readonly updateUserPreferences: {
    readonly user: {
      readonly preferences: {
        readonly language: string;
      };
    };
  } | null;
};
export type Profile_updateUserPreferences_Mutation = {
  variables: Profile_updateUserPreferences_Mutation$variables;
  response: Profile_updateUserPreferences_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "UserPreferences",
  "kind": "LinkedField",
  "name": "preferences",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "language",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Profile_updateUserPreferences_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserPreferencesPayload",
        "kind": "LinkedField",
        "name": "updateUserPreferences",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Profile_updateUserPreferences_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateUserPreferencesPayload",
        "kind": "LinkedField",
        "name": "updateUserPreferences",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "568c2b0800050c4fa9273b6970cebd3f",
    "id": null,
    "metadata": {},
    "name": "Profile_updateUserPreferences_Mutation",
    "operationKind": "mutation",
    "text": "mutation Profile_updateUserPreferences_Mutation(\n  $input: UpdateUserPreferencesInput!\n) {\n  updateUserPreferences(input: $input) {\n    user {\n      preferences {\n        language\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d0da53438163ea2a5354f5e068cc0901";

export default node;
