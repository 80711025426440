import React, { useCallback, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { Link, Route } from "Navigation";
import Button from "components/Button";
import Can from "components/Can";
import MultiSelect from "components/MultiSelect";
import Stack from "components/Stack";
import { availableLanguages } from "i18n";
import { useViewer } from "contexts/Viewer";

type Role = {
  id: string;
  name: string;
};

interface Data {
  roles: Role[];
  email: string;
  name: string;
  language: string;
  organization: {
    name: string;
  };
}

interface Props {
  className?: string;
  readonly?: boolean;
  roles?: Role[];
  value: Data;
  emailVerified: boolean;
  onChange?: (data: Data, isValid: boolean) => void;
  onUpgradeToFullOrganization?: () => void;
  onUpdatePassword?: () => void;
}

const ProfileForm = ({
  className = "",
  readonly = false,
  roles = [],
  value,
  emailVerified,
  onChange,
  onUpgradeToFullOrganization,
  onUpdatePassword,
}: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const intl = useIntl();
  const { isThinOrganization } = useViewer();

  const handleFormChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLSelectElement
  > = useCallback(
    (event) => {
      const target = event.target;
      const fieldValue =
        target.type === "checkbox" && "checked" in target
          ? target.checked
          : target.value;
      const field = target.id;
      const newValue = { ...value, [field]: fieldValue };
      const isValid =
        !!formRef.current?.checkValidity() && newValue.roles.length > 0;
      onChange && onChange(newValue, isValid);
    },
    [value, onChange]
  );

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = handleFormChange;

  const handleSelectChange: React.ChangeEventHandler<HTMLSelectElement> = handleFormChange;

  const handleRolesChange = (newRoles: Role[]) => {
    const newValue = { ...value, roles: newRoles };
    const isValid = !!formRef.current?.checkValidity() && newRoles.length > 0;
    onChange && onChange(newValue, isValid);
  };

  return (
    <Form ref={formRef} className={className} data-testid="profile-form">
      <Row xs={1} lg={2} className="g-4">
        <Col>
          <Card className="h-100 border-0 shadow-sm p-4">
            <Stack gap={3}>
              <h6 className="text-primary">
                <FormattedMessage
                  id="components.ViewerForm.personalDetailsTitle"
                  defaultMessage="Personal Details"
                />
              </h6>
              <Form.Group controlId="name">
                <Form.Label>
                  <FormattedMessage
                    id="components.ViewerForm.nameLabel"
                    defaultMessage="Name"
                    description="Label for the name field in the profile form"
                  />
                </Form.Label>
                <Form.Control
                  name="name"
                  value={value.name}
                  onChange={handleInputChange}
                  readOnly={readonly}
                  plaintext={readonly}
                  required
                  placeholder={intl.formatMessage({
                    id: "components.ViewerForm.namePlaceholder",
                    defaultMessage: "Type in the user name",
                    description:
                      "Placeholder for the name field in the profile form",
                  })}
                  data-testid="profile-form-name"
                />
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>
                  <FormattedMessage
                    id="components.ViewerForm.emailLabel"
                    defaultMessage="Email"
                    description="Label for the email field in the profile form"
                  />
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={value.email}
                  onChange={handleInputChange}
                  readOnly={readonly}
                  plaintext={readonly}
                  isInvalid={!emailVerified}
                  required
                  placeholder={intl.formatMessage({
                    id: "components.ViewerForm.emailPlaceholder",
                    defaultMessage: "Type in the user email",
                    description:
                      "Placeholder for the email field in the profile form",
                  })}
                  data-testid="profile-form-email"
                />
                {!emailVerified && (
                  <Form.Control.Feedback type="invalid">
                    <FormattedMessage
                      id="components.ViewerForm.emailNotVerified"
                      defaultMessage="Email address not verified, <link>click here to verify it</link>."
                      values={{
                        link: (chunks: React.ReactNode) => (
                          <Link route={Route.confirmEmail}>{chunks}</Link>
                        ),
                      }}
                    />
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group controlId="language">
                <Form.Label>
                  <FormattedMessage
                    id="components.ViewerForm.languageLabel"
                    defaultMessage="Language"
                    description="Label for the language field in the profile form"
                  />
                </Form.Label>
                <Form.Select
                  name="language"
                  value={value.language}
                  onChange={handleSelectChange}
                  required
                >
                  <option value="" disabled>
                    {intl.formatMessage({
                      id: "components.ViewerForm.selectLanguagePrompt",
                      defaultMessage: "Select language",
                      description:
                        "Prompt to select the language in the profile form",
                    })}
                  </option>
                  {Object.entries(availableLanguages).map(
                    ([language, languageMeta]) => (
                      <option key={language} value={language}>
                        {languageMeta.nativeName}
                      </option>
                    )
                  )}
                </Form.Select>
              </Form.Group>
              <Form.Group>
                <Form.Label as="div">
                  <FormattedMessage
                    id="components.ViewerForm.passwordLabel"
                    defaultMessage="Password"
                    description="Label for the password field in the profile form"
                  />
                </Form.Label>
                <Button onClick={onUpdatePassword} variant="secondary">
                  <FormattedMessage
                    id="components.ViewerForm.changePassword"
                    defaultMessage="Change Password"
                    description="Label for the button to change the password in the profile form"
                  />
                </Button>
              </Form.Group>
            </Stack>
          </Card>
        </Col>
        <Col>
          <Card className="h-100 border-0 shadow-sm p-4">
            <Stack gap={3}>
              <h6 className="text-primary">
                <FormattedMessage
                  id="components.ViewerForm.organizationDetailsTitle"
                  defaultMessage="Organization Details"
                />
              </h6>
              {isThinOrganization ? (
                <div>
                  <p>
                    <FormattedMessage
                      id="components.ViewerForm.thinOrganizationDescription"
                      defaultMessage="This account is not tied to an organization."
                      description="Label that indicates that the account doesn't belong to an organization"
                    />
                    <br />
                    <FormattedMessage
                      id="components.ViewerForm.upgradeToOrganizationSuggestion"
                      defaultMessage="If you are a retailer, or you need to share control of your appliances with other users, consider upgrading to an organization."
                      description="Label that suggests when to upgrade to an organization"
                    />
                  </p>
                  <Button onClick={onUpgradeToFullOrganization}>
                    <FormattedMessage
                      id="components.ViewerForm.upgradeToOrganizationButton"
                      defaultMessage="Upgrade to organization"
                      description="Label for the button to upgrade to organization in the profile form"
                    />
                  </Button>
                </div>
              ) : (
                <Form.Group controlId="organizationName">
                  <Form.Label>
                    <FormattedMessage
                      id="components.ViewerForm.organizationNameLabel"
                      defaultMessage="Organization"
                      description="Label for the organization's name field in the profile form"
                    />
                  </Form.Label>
                  <Form.Control
                    name="organizationName"
                    value={value.organization.name}
                    onChange={handleInputChange}
                    readOnly={readonly}
                    plaintext={readonly}
                    required
                  />
                </Form.Group>
              )}
              <Can oneOf={["CAN_LIST_ROLES"]}>
                <Form.Group controlId="roles">
                  <Form.Label>
                    <FormattedMessage
                      id="components.ViewerForm.rolesLabel"
                      defaultMessage="Roles"
                      description="Label for the roles field in the profile form"
                    />
                  </Form.Label>
                  <MultiSelect
                    id="roles"
                    name="roles"
                    selected={value.roles}
                    values={roles}
                    getValueId={(role) => role.id}
                    getValueLabel={(role) => role.name}
                    onChange={handleRolesChange}
                    disabled={readonly}
                    data-testid="profile-form-roles"
                  />
                </Form.Group>
              </Can>
            </Stack>
          </Card>
        </Col>
      </Row>
    </Form>
  );
};

export type { Data, Role };

export default ProfileForm;
