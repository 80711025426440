import { useMemo } from "react";
import { RelayEnvironmentProvider } from "react-relay";
import {
  Environment,
  GraphQLResponse,
  Network,
  Observable,
  RecordSource,
  RequestParameters,
  Store,
  Variables,
} from "relay-runtime";
// @ts-ignore TODO: remove this when fixed, see https://github.com/absinthe-graphql/absinthe-socket/issues/43
import { createSubscriber } from "@absinthe/socket-relay";
import * as AbsintheSocket from "@absinthe/socket";
import { Socket as PhoenixSocket } from "phoenix";

import { useSession } from "contexts/Session";

// required to force a re-render of the provider then the environment changes
// FIXME this feels like a hack/workaround. we should better inspect this
// and ask the Relay community about the standard practives to update an environment
let environmentID = 0;

const fetchGraphQL = async (
  query: string | null | undefined,
  variables: Record<string, unknown>,
  authToken: string | null,
  baseBackendUrl: URL,
  tenantSlug: string | null
) => {
  const graphqlUrl = new URL(`tenants/${tenantSlug}/api`, baseBackendUrl);

  const response = await fetch(graphqlUrl.toString(), {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ query, variables }),
  });
  return await response.json();
};

type RelayProviderProps = {
  children: React.ReactNode;
};

const RelayProvider = ({ children }: RelayProviderProps) => {
  const { authToken, baseBackendUrl, tenantSlug } = useSession();

  const fetchRelay = useMemo(() => {
    return async (params: RequestParameters, variables: Variables) =>
      fetchGraphQL(
        params.text,
        variables,
        authToken,
        baseBackendUrl,
        tenantSlug
      );
  }, [authToken, baseBackendUrl, tenantSlug]);

  const subscribe = useMemo(() => {
    if (!authToken) {
      return null;
    }

    const wsUrl = new URL("socket", baseBackendUrl);
    wsUrl.protocol = baseBackendUrl.protocol === "https:" ? "wss:" : "ws:";

    const params = {
      token: authToken,
      tenant_slug: tenantSlug,
    };
    const phoenixSocket = new PhoenixSocket(wsUrl.toString(), { params });
    const legacySubscribe = createSubscriber(
      AbsintheSocket.create(phoenixSocket)
    );

    // @absinthe/socket-relay is outdated so wrap it with a fix
    // see https://github.com/absinthe-graphql/absinthe-socket/issues/44#issuecomment-606349405
    const subscribeFunction = (
      request: any,
      variables: any,
      cacheConfig: any
    ): Observable<GraphQLResponse> => {
      return Observable.create((sink) => {
        const { dispose } = legacySubscribe(request, variables, cacheConfig, {
          onNext: sink.next,
          onError: sink.error,
          onCompleted: sink.complete,
        });
        return dispose;
      });
    };

    return subscribeFunction;
  }, [authToken, baseBackendUrl, tenantSlug]);

  const relayEnvironment = useMemo(() => {
    environmentID++;
    return new Environment({
      network: subscribe
        ? Network.create(fetchRelay, subscribe)
        : Network.create(fetchRelay),
      store: new Store(new RecordSource()),
    });
  }, [fetchRelay, subscribe]);

  return (
    <RelayEnvironmentProvider
      key={environmentID}
      environment={relayEnvironment}
    >
      {children}
    </RelayEnvironmentProvider>
  );
};

export { fetchGraphQL };

export default RelayProvider;
