import React, { useCallback, useEffect, useState } from "react";
import graphql from "babel-plugin-relay/macro";
import {
  useFragment,
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { FormattedMessage, useIntl } from "react-intl";
import type {
  ClientAssignee_getClients_Query,
  ClientAssignee_getClients_Query$data,
} from "api/__generated__/ClientAssignee_getClients_Query.graphql";
import type { ClientAssignee_applianceAssignee$key } from "api/__generated__/ClientAssignee_applianceAssignee.graphql";
import type { ClientAssignee_assignAppliance_Mutation } from "api/__generated__/ClientAssignee_assignAppliance_Mutation.graphql";
import type { ClientAssignee_unassignAppliance_Mutation } from "api/__generated__/ClientAssignee_unassignAppliance_Mutation.graphql";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

import { useCanOneOf } from "components/Can";
import Button from "components/Button";
import ConfirmModal from "components/ConfirmModal";
import Stack from "components/Stack";

type Assignee = ClientAssignee_getClients_Query$data["clients"][0];

const APPLIANCE_ASSIGNEE_FRAGMENT = graphql`
  fragment ClientAssignee_applianceAssignee on Appliance {
    id
    assignee {
      id
      name
    }
  }
`;

const GET_CLIENTS_QUERY = graphql`
  query ClientAssignee_getClients_Query {
    clients {
      id
      name
    }
  }
`;

const ASSIGN_APPLIANCE_MUTATION = graphql`
  mutation ClientAssignee_assignAppliance_Mutation(
    $input: AssignApplianceInput!
  ) {
    assignAppliance(input: $input) {
      appliance {
        id
        assignee {
          id
          name
        }
      }
    }
  }
`;

const UNASSIGN_APPLIANCE_MUTATION = graphql`
  mutation ClientAssignee_unassignAppliance_Mutation(
    $input: UnassignApplianceInput!
  ) {
    unassignAppliance(input: $input) {
      appliance {
        id
        assignee {
          id
          name
        }
      }
    }
  }
`;

const ApplianceNotAssigned = () => (
  <div>
    <FormattedMessage
      id="components.ClientAssignee.applianceNotAssigned"
      defaultMessage="This appliance is not yet assigned"
    />
  </div>
);

const ClientNameRow = ({ children }: { children: React.ReactNode }) => (
  <Form.Group controlId="form-appliance-client">
    <Form.Label>
      <FormattedMessage
        id="components.ClientAssignee.clientName"
        defaultMessage="Client Name"
      />
    </Form.Label>
    {children}
  </Form.Group>
);

const ClientReadOnlyView = ({ client }: { client: Assignee }) => (
  <ClientNameRow>
    <Form.Control
      type="text"
      value={client.name}
      readOnly
      data-testid="assigned-client-field"
    />
  </ClientNameRow>
);

type UnassignViewProps = {
  client: Assignee;
  onUnassign: (previousAssignee: Assignee) => void;
};

const UnassignView = ({ client, onUnassign }: UnassignViewProps) => {
  return (
    <ClientNameRow>
      <div className="d-flex">
        <Form.Control
          type="text"
          value={client.name}
          readOnly
          data-testid="assigned-client-field"
        />
        <Button
          variant="danger"
          className="flex-shrink-0 ms-3"
          onClick={() => onUnassign(client)}
          data-testid="unassign-client-button"
        >
          <FormattedMessage
            id="components.ClientAssignee.unassignClient"
            defaultMessage="Unassign client"
            description="Label for the unassign client button"
          />
        </Button>
      </div>
    </ClientNameRow>
  );
};

const AssigneeReadOnlyView = ({ assignee }: { assignee: Assignee | null }) =>
  assignee ? (
    <ClientReadOnlyView client={assignee} />
  ) : (
    <ApplianceNotAssigned />
  );

const AssigneeUnassignOnlyView = ({
  assignee,
  onUnassign,
}: {
  assignee: Assignee | null;
  onUnassign: (previousAssignee: Assignee) => void;
}) =>
  assignee ? (
    <UnassignView client={assignee} onUnassign={onUnassign} />
  ) : (
    <ApplianceNotAssigned />
  );

type AssigneeFullEditViewProps = {
  getClientsQuery: PreloadedQuery<ClientAssignee_getClients_Query>;
  onAssign: (newAssignee: Assignee) => void;
  onUnassign: (previousAssignee: Assignee) => void;
  value: Assignee | null;
};

const AssigneeFullEditView = ({
  getClientsQuery,
  value,
  onAssign,
  onUnassign,
}: AssigneeFullEditViewProps) => {
  const intl = useIntl();
  const { clients } = usePreloadedQuery(GET_CLIENTS_QUERY, getClientsQuery);

  const handleSelect: React.ChangeEventHandler<HTMLSelectElement> = useCallback(
    (event) => {
      const client = clients.find((c) => c.id === event.target.value);
      if (client) {
        onAssign(client);
      }
    },
    [clients, onAssign]
  );

  if (value) {
    return <UnassignView client={value} onUnassign={onUnassign} />;
  }
  return (
    <ClientNameRow>
      <Form.Select
        value=""
        onChange={handleSelect}
        data-testid="assign-client-select"
      >
        <option value="" disabled>
          {intl.formatMessage({
            id: "components.ClientAssignee.selectClientPrompt",
            defaultMessage: "Select client",
            description: "Prompt to select the client in the Appliance page",
          })}
        </option>
        {clients.map((client) => (
          <option key={client.id} value={client.id}>
            {client.name}
          </option>
        ))}
      </Form.Select>
    </ClientNameRow>
  );
};

type FormMode = "ReadOnly" | "UnassignOnly" | "FullEdit";

type AssigneeFormProps = {
  assignee: Assignee | null;
  formMode: FormMode;
  onAssigneeChange: (client: Assignee) => void;
  onUnassign: (previousClient: Assignee) => void;
};

const AssigneeForm = ({
  assignee,
  formMode,
  onAssigneeChange,
  onUnassign,
}: AssigneeFormProps) => {
  const [
    getClientsQuery,
    getClients,
  ] = useQueryLoader<ClientAssignee_getClients_Query>(GET_CLIENTS_QUERY);

  useEffect(() => {
    if (formMode === "FullEdit") {
      getClients({});
    }
  }, [getClients, formMode]);

  return (
    <Form>
      {formMode === "FullEdit" && getClientsQuery && (
        <AssigneeFullEditView
          getClientsQuery={getClientsQuery}
          value={assignee}
          onAssign={onAssigneeChange}
          onUnassign={onUnassign}
        />
      )}
      {formMode === "UnassignOnly" && (
        <AssigneeUnassignOnlyView assignee={assignee} onUnassign={onUnassign} />
      )}
      {formMode === "ReadOnly" && <AssigneeReadOnlyView assignee={assignee} />}
    </Form>
  );
};

type AssignClientModal = { id: "assign-client-modal"; assignee: Assignee };
type UnassignClientModal = {
  id: "unassign-client-modal";
  previousAssignee: Assignee;
};
type PageModal = AssignClientModal | UnassignClientModal;

type ClientAssigneeProps = {
  applianceRef: ClientAssignee_applianceAssignee$key;
};

const ClientAssignee = ({ applianceRef }: ClientAssigneeProps) => {
  const [activeModal, setActiveModal] = useState<PageModal | null>(null);
  const [
    assignErrorFeedback,
    setAssignErrorFeedback,
  ] = useState<React.ReactNode>(null);
  const applianceAssigneeData = useFragment(
    APPLIANCE_ASSIGNEE_FRAGMENT,
    applianceRef
  );
  const [
    assignAppliance,
    isAssigningAppliance,
  ] = useMutation<ClientAssignee_assignAppliance_Mutation>(
    ASSIGN_APPLIANCE_MUTATION
  );
  const [
    unassignAppliance,
    isUnassigningAppliance,
  ] = useMutation<ClientAssignee_unassignAppliance_Mutation>(
    UNASSIGN_APPLIANCE_MUTATION
  );

  const applianceId = applianceAssigneeData.id;
  const assignee = applianceAssigneeData.assignee;
  const canListClients = useCanOneOf(["CAN_LIST_CLIENTS"]);
  const canChangeAssignee = useCanOneOf(["CAN_ASSIGN_APPLIANCES"]);

  const handleAssignAppliance = useCallback(
    (newAssignee: Assignee) => {
      const clientId = newAssignee.id;
      assignAppliance({
        variables: { input: { applianceId, clientId } },
        onCompleted(data, errors) {
          setActiveModal(null);
          if (errors) {
            const assignErrorFeedback = errors
              .map((error) => error.message)
              .join(". \n");
            return setAssignErrorFeedback(assignErrorFeedback);
          }
        },
        onError(error) {
          setActiveModal(null);
          setAssignErrorFeedback(
            <FormattedMessage
              id="components.ClientAssignee.assignApplianceErrorFeedback"
              defaultMessage="Could not assign the appliance, please try again."
              description="Feedback for unknown error while assigning an appliance"
            />
          );
        },
        updater(store) {
          // TODO: should use and update Connections instead of invalidating the entire store
          // see https://relay.dev/docs/guided-tour/list-data/updating-connections/
          store.invalidateStore();
        },
      });
    },
    [assignAppliance, applianceId]
  );

  const handleUnassignAppliance = useCallback(
    (previousAssignee: Assignee) => {
      const clientId = previousAssignee.id;
      unassignAppliance({
        variables: { input: { applianceId, clientId } },
        onCompleted(data, errors) {
          setActiveModal(null);
          if (errors) {
            const assignErrorFeedback = errors
              .map((error) => error.message)
              .join(". \n");
            return setAssignErrorFeedback(assignErrorFeedback);
          }
        },
        onError(error) {
          setActiveModal(null);
          setAssignErrorFeedback(
            <FormattedMessage
              id="components.ClientAssignee.unassignApplianceErrorFeedback"
              defaultMessage="Could not unassign the appliance, please try again."
              description="Feedback for unknown error while unassigning an appliance"
            />
          );
        },
        updater(store) {
          // TODO: should use and update Connections instead of invalidating the entire store
          // see https://relay.dev/docs/guided-tour/list-data/updating-connections/
          store.invalidateStore();
        },
      });
    },
    [unassignAppliance, applianceId]
  );

  if (!canListClients && !canChangeAssignee) {
    return null;
  }

  let formMode: FormMode;
  if (canListClients && canChangeAssignee) {
    formMode = "FullEdit";
  } else if (canChangeAssignee) {
    formMode = "UnassignOnly";
  } else {
    formMode = "ReadOnly";
  }

  return (
    <Card className="h-100 border-0 shadow-sm p-4">
      <Stack gap={3}>
        <h6 className="text-primary">
          <FormattedMessage
            id="components.ClientAssignee.clientInfo"
            defaultMessage="Client Info"
          />
        </h6>
        <Alert
          show={!!assignErrorFeedback}
          variant="danger"
          onClose={() => setAssignErrorFeedback(null)}
          dismissible
        >
          {assignErrorFeedback}
        </Alert>
        <AssigneeForm
          assignee={assignee}
          formMode={formMode}
          onAssigneeChange={(assignee) =>
            setActiveModal({
              id: "assign-client-modal",
              assignee,
            })
          }
          onUnassign={(previousAssignee) =>
            setActiveModal({
              id: "unassign-client-modal",
              previousAssignee,
            })
          }
        />
      </Stack>
      {activeModal?.id === "assign-client-modal" && (
        <ConfirmModal
          title={
            <FormattedMessage
              id="components.ClientAssignee.assignModal.title"
              defaultMessage="Assign Client"
              description="Title for the Assign Client modal in the Appliance Page"
            />
          }
          confirmLabel={
            <FormattedMessage
              id="components.ClientAssignee.assignModal.confirmButton"
              defaultMessage="Assign"
              description="Title for the confirm button of the Assign Client modal in the Appliance Page"
            />
          }
          onCancel={() => setActiveModal(null)}
          onConfirm={() => handleAssignAppliance(activeModal.assignee)}
          isConfirming={isAssigningAppliance}
          data-testid="assign-client-modal"
        >
          <p>
            <FormattedMessage
              id="components.ClientAssignee.assignModal.confirmPrompt"
              defaultMessage="Are you sure you want to assign this appliance to the client <bold>{client}</bold>?"
              description="Description of the action to confirm for the Assign Client modal"
              values={{
                client: activeModal.assignee.name,
                bold: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
              }}
            />
          </p>
        </ConfirmModal>
      )}
      {activeModal?.id === "unassign-client-modal" && (
        <ConfirmModal
          title={
            <FormattedMessage
              id="components.ClientAssignee.unassignModal.title"
              defaultMessage="Unassign Client"
              description="Title for the Unassign Client modal in the Appliance Page"
            />
          }
          confirmLabel={
            <FormattedMessage
              id="components.ClientAssignee.unassignModal.confirmButton"
              defaultMessage="Unassign"
              description="Title for the confirm button of the Unassign Client modal in the Appliance Page"
            />
          }
          confirmVariant="danger"
          onCancel={() => setActiveModal(null)}
          onConfirm={() =>
            handleUnassignAppliance(activeModal.previousAssignee)
          }
          isConfirming={isUnassigningAppliance}
          data-testid="unassign-client-modal"
        >
          <p>
            <FormattedMessage
              id="components.ClientAssignee.unassignModal.confirmPrompt"
              defaultMessage="Are you sure you want to unassign this appliance from the client <bold>{client}</bold>?"
              description="Description of the action to confirm for the Unassign Client modal"
              values={{
                client: activeModal.previousAssignee.name,
                bold: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
              }}
            />
          </p>
        </ConfirmModal>
      )}
    </Card>
  );
};

export default ClientAssignee;
