import React from "react";

import * as images from "assets/images";

interface Props {
  children?: React.ReactNode;
  image?: string;
  title?: string | JSX.Element;
  "data-testid"?: string;
}

const ResultWrapper = ({ children, image, title, ...restProps }: Props) => {
  return (
    <div
      {...restProps}
      className="p-5 d-flex justify-content-center align-items-center"
    >
      {image && <img alt="Result" width="250em" src={image} />}
      <div className={image ? "ms-5" : "text-center"}>
        {title && <h4>{title}</h4>}
        {children}
      </div>
    </div>
  );
};

const Empty = ({ image = images.empty, ...restProps }: Props) => (
  <ResultWrapper image={image} {...restProps} />
);

const Error = ({ image = images.warning, ...restProps }: Props) => (
  <ResultWrapper image={image} {...restProps} />
);

const NotFound = ({ image = images.warning, ...restProps }: Props) => (
  <ResultWrapper image={image} {...restProps} />
);

const Result = {
  Empty,
  Error,
  NotFound,
};

export default Result;
