import React, { useCallback } from "react";

import type Permission from "api/Permission";
import { useViewer } from "contexts/Viewer";

const thinOrganizationPermissions = [
  "CAN_CREATE_APPLIANCES",
  "CAN_EDIT_APPLIANCES",
  "CAN_GET_APPLIANCES",
  "CAN_LIST_APPLIANCES",
];

const useCan = () => {
  const {
    acceptedLatestTermsAndConditions,
    userPermissions,
    isThinOrganization,
  } = useViewer();

  const canOneOf = useCallback(
    (permissions: Permission[] = []): boolean => {
      if (permissions.length === 0) {
        return true;
      }
      if (!acceptedLatestTermsAndConditions) {
        return false;
      }
      return isThinOrganization
        ? permissions.some((permission) =>
            thinOrganizationPermissions.includes(permission)
          )
        : permissions.some((permission) =>
            userPermissions.includes(permission)
          );
    },
    [acceptedLatestTermsAndConditions, isThinOrganization, userPermissions]
  );

  return { canOneOf };
};

const useCanOneOf = (permissions?: Permission[]) => {
  const { canOneOf } = useCan();
  return canOneOf(permissions);
};

interface Props {
  children?: React.ReactNode | ((can: boolean) => React.ReactNode);
  oneOf: Permission[];
}

const Can = ({ children, oneOf }: Props) => {
  const can = useCanOneOf(oneOf);

  if (typeof children === "function") {
    return children(can);
  }

  if (!can) {
    return null;
  }

  return <React.Fragment>{children}</React.Fragment>;
};

export type { Permission };

export { useCan, useCanOneOf };

export default Can;
