import { FormattedMessage } from "react-intl";
import Form from "react-bootstrap/Form";
import _ from "lodash";

type FormFeedbackProps = {
  feedback?:
    | string
    | {
        templateId: string;
        values: Record<string, string>;
      };
};

const FormFeedback = ({ feedback }: FormFeedbackProps) => {
  if (!feedback) {
    return null;
  }

  if (_.isObject(feedback)) {
    return (
      <Form.Control.Feedback type="invalid">
        <FormattedMessage id={feedback.templateId} values={feedback.values} />
      </Form.Control.Feedback>
    );
  }

  return (
    <Form.Control.Feedback type="invalid">
      <FormattedMessage id={feedback} />
    </Form.Control.Feedback>
  );
};

export default FormFeedback;
