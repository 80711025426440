import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { commitLocalUpdate, useRelayEnvironment } from "react-relay";

import Page, { PageLoading } from "components/Page";
import { useSession } from "contexts/Session";

const Logout = () => {
  const { setAuthToken } = useSession();
  const relayEnvironment = useRelayEnvironment();

  useEffect(() => {
    // @ts-expect-error wrong Relay types, store should be a RecordSourceSelectorProxy
    commitLocalUpdate(relayEnvironment, (store) => store.invalidateStore());
    setAuthToken(null);
  }, [relayEnvironment, setAuthToken]);

  return (
    <Page
      title={
        <FormattedMessage
          id="pages.Logout.title"
          defaultMessage="Logout"
          description="Title for the Logout page"
        />
      }
    >
      <PageLoading />
    </Page>
  );
};

export default Logout;
