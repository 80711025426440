import React, { useCallback, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import Form from "react-bootstrap/Form";
import Stack from "components/Stack";

type ApplianceDraft = {
  serial: string;
  name: string;
  deviceId: string;
};

interface Props {
  className?: string;
  value: ApplianceDraft;
  onChange?: (data: ApplianceDraft, isValid: boolean) => void;
}

const UserForm = ({ className = "", value, onChange }: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const intl = useIntl();

  const handleInputChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const target = event.target;
      const fieldValue = target.value;
      const field = target.id;
      const newValue = { ...value, [field]: fieldValue };
      const isFormValid = !!formRef.current?.checkValidity();
      onChange && onChange(newValue, isFormValid);
    },
    [value, onChange]
  );

  return (
    <Form ref={formRef} className={className} data-testid="appliance-form">
      <Stack gap={3}>
        <Form.Group controlId="name">
          <Form.Label>
            <FormattedMessage
              id="components.ApplianceForm.nameLabel"
              defaultMessage="Name"
              description="Label for the name field in the appliance form"
            />
          </Form.Label>
          <Form.Control
            value={value.name}
            onChange={handleInputChange}
            required
            placeholder={intl.formatMessage({
              id: "components.ApplianceForm.namePlaceholder",
              defaultMessage: "Type in the appliance name",
              description:
                "Placeholder for the name field in the appliance form",
            })}
            name="name"
            data-testid="appliance-form-name"
          />
        </Form.Group>
        <Form.Group controlId="serial">
          <Form.Label>
            <FormattedMessage
              id="components.ApplianceForm.serialLabel"
              defaultMessage="Serial"
              description="Label for the serial field in the appliance form"
            />
          </Form.Label>
          <Form.Control
            value={value.serial}
            onChange={handleInputChange}
            required
            placeholder={intl.formatMessage({
              id: "components.ApplianceForm.serialPlaceholder",
              defaultMessage: "Type in the appliance serial",
              description:
                "Placeholder for the serial field in the appliance form",
            })}
            name="serial"
            data-testid="appliance-form-serial"
          />
        </Form.Group>
        <Form.Group controlId="deviceId">
          <Form.Label>
            <FormattedMessage
              id="components.ApplianceForm.deviceIdLabel"
              defaultMessage="Device ID"
              description="Label for the deviceId field in the appliance form"
            />
          </Form.Label>
          <Form.Control
            value={value.deviceId}
            onChange={handleInputChange}
            required
            placeholder={intl.formatMessage({
              id: "components.ApplianceForm.deviceIdPlaceholder",
              defaultMessage: "Type in the Astarte device ID",
              description:
                "Placeholder for the deviceId field in the appliance form",
            })}
            name="deviceId"
            data-testid="appliance-form-deviceId"
          />
        </Form.Group>
      </Stack>
    </Form>
  );
};

export type { ApplianceDraft };

export default UserForm;
