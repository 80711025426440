import { FormattedMessage } from "react-intl";
import { Container } from "react-bootstrap";

import type { AcceptedCookies } from "contexts/Session";
import Button from "components/Button";
import Stack from "components/Stack";

import "./CookiePreferences.scss";

type CookiePreferencesProps = {
  onSelect: (acceptedCookies: AcceptedCookies) => void;
  onShowCookiePolicy: () => void;
};

const CookiePreferences = ({
  onSelect,
  onShowCookiePolicy,
}: CookiePreferencesProps) => {
  const handleCookieLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    onShowCookiePolicy();
  };
  return (
    <aside className="cookie-preferences bg-dark text-light position-absolute">
      <Stack
        as={Container}
        direction="horizontal"
        gap={2}
        className="p-3 d-flex"
      >
        <span className="me-auto">
          <FormattedMessage
            id="cookiePreferences.description"
            defaultMessage="This site uses cookies to offer you a better browsing experience. Find out more on how we use <a>cookies</a>."
            values={{
              a: (chunks: React.ReactNode) => (
                <a
                  href="/cookiePolicy"
                  onClick={handleCookieLinkClick}
                  className="text-light"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </span>
        <Button variant="primary" onClick={() => onSelect("All")}>
          <FormattedMessage
            id="cookiePreferences.acceptAll"
            defaultMessage="Accept all cookies"
          />
        </Button>
        <Button
          variant="secondary"
          onClick={() => onSelect("StrictlyNecessary")}
        >
          <FormattedMessage
            id="cookiePreferences.acceptStrictlyNecessary"
            defaultMessage="Accept only essential cookies"
          />
        </Button>
      </Stack>
    </aside>
  );
};

export default CookiePreferences;
