import { ComponentProps } from "react";
import RBButton from "react-bootstrap/Button";

// Define the 'as' prop with correct types
// See issue: https://github.com/react-bootstrap/react-bootstrap/issues/6103
type RBButtonProps = ComponentProps<typeof RBButton>;
type Props = Omit<RBButtonProps, "as"> & {
  as?: "button" | "a" | React.ElementType;
};

const Button = (props: Props) => (
  // @ts-expect-error wrong types
  <RBButton {...props} />
);

export default Button;
