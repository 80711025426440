import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import _ from "lodash";

import SegmentedControl from "components/SegmentedControl";

type EventKey = string;

type TabRef = {
  eventKey: EventKey;
  title: React.ReactNode;
};

type TabsContextValue = {
  activeKey: EventKey | undefined;
  registerTab: (tabRef: TabRef) => void;
  unregisterTab: (eventKey: EventKey) => void;
};

const defaultContextValue: TabsContextValue = {
  activeKey: undefined,
  registerTab: () => {},
  unregisterTab: () => {},
};

const TabsContext = createContext<TabsContextValue>(defaultContextValue);

type TabsProps = {
  children?: React.ReactNode;
  className?: string;
  defaultActiveKey?: EventKey;
  tabsOrder?: EventKey[];
};

const Tabs = ({
  children,
  className,
  defaultActiveKey,
  tabsOrder = [],
}: TabsProps) => {
  const [activeKey, setActiveKey] = useState<EventKey | undefined>(
    defaultActiveKey
  );
  const [tabRefs, setTabRefs] = useState<TabRef[]>([]);

  const registerTab = useCallback((tabRef: TabRef) => {
    setTabRefs((refs) => {
      setActiveKey((activeKey) => activeKey ?? tabRef.eventKey);
      return _.uniqBy([...refs, tabRef], "eventKey");
    });
  }, []);

  const unregisterTab = useCallback((eventKey: EventKey) => {
    setTabRefs((tabRefs) => {
      const newTabRefs = tabRefs.filter(
        (tabRef) => tabRef.eventKey !== eventKey
      );
      setActiveKey((activeKey) =>
        activeKey === eventKey ? newTabRefs[0]?.eventKey : activeKey
      );
      return newTabRefs;
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      activeKey,
      registerTab,
      unregisterTab,
    }),
    [activeKey, registerTab, unregisterTab]
  );

  const sortedTabRefs = useMemo(() => {
    const tabRefsByEventKey = _.keyBy(tabRefs, "eventKey");
    const eventKeys = _.keys(tabRefsByEventKey);
    // 1. intersect tabsOrder with eventKeys to pick eventKeys in the correct order
    // 2. union the result with eventKeys to pick the remaining eventKeys
    const sortedEventKeys = _.union(
      _.intersection(tabsOrder, eventKeys),
      eventKeys
    );
    return sortedEventKeys.map((eventKey) => tabRefsByEventKey[eventKey]);
  }, [tabRefs, tabsOrder]);

  return (
    <TabsContext.Provider value={contextValue}>
      <div className={className}>
        {sortedTabRefs.length > 0 && (
          <SegmentedControl
            activeId={activeKey}
            items={sortedTabRefs}
            getItemId={(tabRef) => tabRef.eventKey}
            className="bg-light"
            onChange={setActiveKey}
          >
            {(tabRef) => <div className="mx-3 my-1">{tabRef.title}</div>}
          </SegmentedControl>
        )}
        {children}
      </div>
    </TabsContext.Provider>
  );
};

const useTabs = (): TabsContextValue => {
  const tabsContextValue = useContext(TabsContext);
  if (tabsContextValue == null) {
    throw new Error("TabsContext has not been Provided");
  }
  return tabsContextValue;
};

type CommonProps<A, B> = {
  [a in keyof (A & B)]: (A & B)[a];
};

type Spread<A, B> = (CommonProps<A, B> & A) | B;

// Use custom Spread type to get all correct props of "div", since TypeScript does not
// support spreading yet: https://github.com/microsoft/TypeScript/issues/10727
type TabProps = Spread<
  React.ComponentProps<"div">,
  {
    children?: React.ReactNode;
    eventKey: EventKey;
    title?: string;
  }
>;

const Tab = ({ eventKey, title, ...restProps }: TabProps) => {
  const { registerTab, unregisterTab, activeKey } = useTabs();

  useEffect(() => {
    registerTab({ eventKey, title });
    return () => unregisterTab(eventKey);
  }, [registerTab, unregisterTab, eventKey, title]);

  const isActive = activeKey === eventKey;

  if (!isActive) {
    return null;
  }

  return <div {...restProps} />;
};

export { Tab };

export default Tabs;
