/**
 * @generated SignedSource<<c6cb85798c933bb87b7e09710bc5dad1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Appliance_provisioningStatus$data = {
  readonly provisioningStatus: {
    readonly osCodeId: string | null;
    readonly osVersion: string | null;
  } | null;
  readonly " $fragmentType": "Appliance_provisioningStatus";
};
export type Appliance_provisioningStatus$key = {
  readonly " $data"?: Appliance_provisioningStatus$data;
  readonly " $fragmentSpreads": FragmentRefs<"Appliance_provisioningStatus">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Appliance_provisioningStatus",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ProvisioningStatus",
      "kind": "LinkedField",
      "name": "provisioningStatus",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "osCodeId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "osVersion",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Device",
  "abstractKey": null
};

(node as any).hash = "7623649d0e7e4f65845d42246a83b2c1";

export default node;
