import { FormattedMessage } from "react-intl";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { messages, yup } from "forms";

import Button from "components/Button";
import Spinner from "components/Spinner";
import FormFeedback from "forms/FormFeedback";
import Stack from "components/Stack";

type UpdatePasswordData = {
  currentPassword: string;
  newPassword: string;
};

type FormData = {
  currentPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
};

const fromDataSchema = yup
  .object({
    currentPassword: yup.string().required(),
    newPassword: yup.string().required().min(3),
    newPasswordConfirm: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], messages.passwordConfirm.id),
  })
  .required();

const transformOutputData = (data: FormData): UpdatePasswordData => ({
  currentPassword: data.currentPassword,
  newPassword: data.newPassword,
});

type UpdatePasswordModalProps = {
  isConfirming?: boolean;
  onCancel: () => void;
  onConfirm: (data: UpdatePasswordData) => void;
};

const UpdatePasswordModal = ({
  isConfirming = false,
  onCancel,
  onConfirm,
}: UpdatePasswordModalProps): React.ReactElement => {
  const {
    register,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "onTouched",
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    },
    resolver: yupResolver(fromDataSchema),
  });

  const onFormSubmit = (data: FormData) => onConfirm(transformOutputData(data));
  const handleConfirm = () => handleSubmit(onFormSubmit)();

  return (
    <Modal show centered size="lg" onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>
          {
            <FormattedMessage
              id="components.UpdatePasswordModal.title"
              defaultMessage="Change Password"
              description="Title for the update password modal in the Profile Page"
            />
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onFormSubmit)}>
          <Stack gap={3}>
            <Form.Group controlId="current-password">
              <Form.Label>
                <FormattedMessage
                  id="components.UpdatePasswordModal.currentPasswordLabel"
                  defaultMessage="Current password"
                />
              </Form.Label>
              <Form.Control
                type="password"
                isInvalid={!!errors.currentPassword}
                {...register("currentPassword")}
              />
              <FormFeedback feedback={errors.currentPassword?.message} />
            </Form.Group>
            <Form.Group controlId="new-password">
              <Form.Label>
                <FormattedMessage
                  id="components.UpdatePasswordModal.newPasswordLabel"
                  defaultMessage="New password"
                />
              </Form.Label>
              <Form.Control
                type="password"
                isInvalid={!!errors.newPassword}
                {...register("newPassword")}
              />
              <FormFeedback feedback={errors.newPassword?.message} />
            </Form.Group>
            <Form.Group controlId="new-password-confirm">
              <Form.Label>
                <FormattedMessage
                  id="components.UpdatePasswordModal.newPasswordConfirmLabel"
                  defaultMessage="Confirm new password"
                />
              </Form.Label>
              <Form.Control
                type="password"
                isInvalid={!!errors.newPasswordConfirm}
                {...register("newPasswordConfirm")}
              />
              <FormFeedback feedback={errors.newPasswordConfirm?.message} />
            </Form.Group>
          </Stack>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          <FormattedMessage
            id="components.UpdatePasswordModal.cancelButton"
            defaultMessage="Cancel"
            description="Title for the cancel button of the update password modal in the Profile Page"
          />
        </Button>
        <Button variant="primary" disabled={!isValid} onClick={handleConfirm}>
          {isConfirming && <Spinner className="me-2" size="sm" />}
          <FormattedMessage
            id="components.UpdatePasswordModal.confirmButton"
            defaultMessage="Change password"
            description="Title for the confirm button of the update password modal in the Profile Page"
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdatePasswordModal;
