import React from "react";
import { FormattedDate, FormattedMessage } from "react-intl";

import StyledTable from "components/StyledTable";
import type { Column } from "components/StyledTable";
import { Link, Route } from "Navigation";
import ConnectionStatus from "components/ConnectionStatus";
import Tag from "components/Tag";

type ApplianceProps = {
  id: string;
  name: string;
  assignee: { id: string; name: string } | null;
  serial: string;
  tags: string[];
  device: {
    lastConnection: string | null;
    lastDisconnection: string | null;
    online: boolean;
  };
};

const columns: Column<ApplianceProps>[] = [
  {
    id: "status",
    accessor: (appliance) => Boolean(appliance.device.online),
    Header: (
      <FormattedMessage
        id="components.AppliancesTable.statusTitle"
        defaultMessage="Status"
        description="Title for the Status column of the appliances table"
      />
    ),
    Cell: ({ value }: { value: boolean }) => (
      <ConnectionStatus connected={value} />
    ),
    sortType: "basic",
  },
  {
    accessor: "name",
    Header: (
      <FormattedMessage
        id="components.AppliancesTable.nameTitle"
        defaultMessage="Appliance Name"
        description="Title for the Name column of the appliances table"
      />
    ),
    Cell: ({ row, value }) => (
      <Link
        route={Route.appliancesEdit}
        params={{ applianceId: row.original.id }}
      >
        {value}
      </Link>
    ),
  },
  {
    accessor: "serial",
    Header: (
      <FormattedMessage
        id="components.AppliancesTable.serialTitle"
        defaultMessage="Appliance S/N"
        description="Title for the Serial column of the appliances table"
      />
    ),
  },
  {
    id: "assignee",
    accessor: (appliance) => appliance.assignee?.name,
    Header: (
      <FormattedMessage
        id="components.AppliancesTable.assigneeTitle"
        defaultMessage="Assignee"
        description="Title for the Assignee column of the appliances table"
      />
    ),
  },
  {
    id: "lastSeen",
    accessor: (appliance) => {
      if (appliance.device.online) {
        return "now";
      } else {
        return appliance.device.lastDisconnection || "never";
      }
    },
    Header: (
      <FormattedMessage
        id="components.AppliancesTable.lastSeenTitle"
        defaultMessage="Last Seen"
        description="Title for the Last Seen column of the appliances table"
      />
    ),
    Cell: ({ value }: { value: string }) => {
      if (value === "now") {
        return (
          <FormattedMessage
            id="components.AppliancesTable.lastSeen.now"
            defaultMessage="Now"
            description="Label in the LastSeen column for an appliance that is online"
          />
        );
      } else if (value === "never") {
        return (
          <FormattedMessage
            id="components.AppliancesTable.lastSeen.never"
            defaultMessage="Never"
            description="Label in the LastSeen column for an appliance that never connected"
          />
        );
      } else {
        return (
          <FormattedDate
            value={new Date(value)}
            year="numeric"
            month="long"
            day="numeric"
            hour="numeric"
            minute="numeric"
          />
        );
      }
    },
  },
  {
    accessor: "tags",
    disableSortBy: true,
    Header: (
      <FormattedMessage
        id="components.AppliancesTable.tagsTitle"
        defaultMessage="Tags"
        description="Title for the Tags column of the appliances table"
      />
    ),
    Cell: ({ value }) => (
      <>
        {value.map((tag) => (
          <Tag key={tag} className="me-2">
            {tag}
          </Tag>
        ))}
      </>
    ),
  },
];

interface Props {
  className?: string;
  data: ApplianceProps[];
  hideAssignee?: boolean;
  searchText?: string;
}

const AppliancesTable = ({
  className,
  data,
  hideAssignee = false,
  searchText,
}: Props) => {
  return (
    <StyledTable
      className={className}
      columns={columns}
      data={data}
      hiddenColumns={hideAssignee ? ["assignee"] : []}
      searchText={searchText}
    />
  );
};

export type { ApplianceProps };

export default AppliancesTable;
