import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";
import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";

import { useCan } from "components/Can";
import Icon from "components/Icon";
import { useTenantConfig } from "contexts/TenantConfig";
import { Link, matchPaths, ParametricRoute, Route } from "Navigation";
import "./Topbar.scss";

type MenuItem = {
  label: React.ReactNode;
  activeRoutes: Route | Route[];
  link: ParametricRoute;
  visible: boolean;
};

type MenuGroup = {
  label: React.ReactNode;
  icon: React.ComponentProps<typeof Icon>["icon"];
  items: MenuItem[];
};

type Menu = MenuGroup[];

const useMenu = (): Menu => {
  const intl = useIntl();
  const { canOneOf } = useCan();
  const { deviceClaimInsteadOfApplianceRegistration } = useTenantConfig();

  return useMemo(() => {
    const menu: Menu = [
      {
        label: intl.formatMessage({
          id: "components.Topbar.appliancesGroupLabel",
          defaultMessage: "Appliances",
        }),
        icon: "appliances",
        items: [
          {
            label: intl.formatMessage({
              id: "components.Topbar.appliancesLinkLabel",
              defaultMessage: "Appliance List",
            }),
            link: { route: Route.appliances },
            activeRoutes: [Route.appliances, Route.appliancesEdit],
            visible: canOneOf(["CAN_LIST_APPLIANCES"]),
          },
          {
            label: intl.formatMessage({
              id: "components.Topbar.registerApplianceLinkLabel",
              defaultMessage: "Register Appliance",
            }),
            link: { route: Route.appliancesNew },
            activeRoutes: [Route.appliancesNew],
            visible:
              canOneOf(["CAN_CREATE_APPLIANCES"]) &&
              !deviceClaimInsteadOfApplianceRegistration,
          },
          {
            label: intl.formatMessage({
              id: "components.Topbar.claimApplianceLinkLabel",
              defaultMessage: "Claim Appliance",
            }),
            link: { route: Route.devicesClaim },
            activeRoutes: [Route.devicesClaim],
            visible:
              canOneOf(["CAN_CREATE_APPLIANCES"]) &&
              deviceClaimInsteadOfApplianceRegistration,
          },
        ],
      },
      {
        label: intl.formatMessage({
          id: "components.Topbar.clientsGroupLabel",
          defaultMessage: "Clients",
        }),
        icon: "clients",
        items: [
          {
            label: intl.formatMessage({
              id: "components.Topbar.clientsLinkLabel",
              defaultMessage: "Client List",
            }),
            link: { route: Route.clients },
            activeRoutes: [Route.clients, Route.clientsEdit],
            visible: canOneOf(["CAN_LIST_CLIENTS"]),
          },
          {
            label: intl.formatMessage({
              id: "components.Topbar.addClientLinkLabel",
              defaultMessage: "Add Client",
            }),
            link: { route: Route.clientsNew },
            activeRoutes: [Route.clientsNew],
            visible: canOneOf(["CAN_ADD_CLIENTS"]),
          },
        ],
      },
      {
        label: intl.formatMessage({
          id: "components.Topbar.UsersGroupLabel",
          defaultMessage: "Users",
        }),
        icon: "users",
        items: [
          {
            label: intl.formatMessage({
              id: "components.Topbar.usersLinkLabel",
              defaultMessage: "User List",
            }),
            link: { route: Route.users },
            activeRoutes: [Route.users, Route.usersEdit],
            visible: canOneOf(["CAN_LIST_USERS"]),
          },
          {
            label: intl.formatMessage({
              id: "components.Topbar.inviteUsersLinkLabel",
              defaultMessage: "Invite Users",
            }),
            link: { route: Route.usersInvite },
            activeRoutes: [Route.usersInvite],
            visible: canOneOf(["CAN_INVITE_USERS"]),
          },
          {
            label: intl.formatMessage({
              id: "components.Topbar.rolesLinkLabel",
              defaultMessage: "Role List",
            }),
            link: { route: Route.roles },
            activeRoutes: [Route.roles, Route.rolesEdit],
            visible: canOneOf(["CAN_LIST_ROLES"]),
          },
          {
            label: intl.formatMessage({
              id: "components.Topbar.createRoleLinkLabel",
              defaultMessage: "Create Role",
            }),
            link: { route: Route.rolesNew },
            activeRoutes: [Route.rolesNew],
            visible: canOneOf(["CAN_CREATE_ROLES"]),
          },
        ],
      },
      {
        label: intl.formatMessage({
          id: "components.Topbar.profileGroupLabel",
          defaultMessage: "Profile",
        }),
        icon: "profile",
        items: [
          {
            label: intl.formatMessage({
              id: "components.Topbar.profileLinkLabel",
              defaultMessage: "Profile Details",
            }),
            link: { route: Route.profile },
            activeRoutes: [Route.profile, Route.confirmEmail],
            visible: true,
          },
          {
            label: intl.formatMessage({
              id: "components.Topbar.logoutLinkLabel",
              defaultMessage: "Logout",
            }),
            link: { route: Route.logout },
            activeRoutes: [Route.logout],
            visible: true,
          },
        ],
      },
    ];
    return menu
      .map((group) => ({
        ...group,
        items: group.items.filter((item) => item.visible),
      }))
      .filter((group) => group.items.length > 0);
  }, [intl, canOneOf, deviceClaimInsteadOfApplianceRegistration]);
};

const getActiveMenuItem = (menu: Menu, currentPath: string) => {
  const menuItems = menu.flatMap((group) => group.items);
  return (
    menuItems.find((item) => matchPaths(item.activeRoutes, currentPath)) ||
    menu[0].items[0]
  );
};

const getActiveMenuGroup = (menu: Menu, item: MenuItem) => {
  return menu.find((group) => group.items.includes(item)) || menu[0];
};

const Topbar = () => {
  const location = useLocation();
  const menu = useMenu();
  const [activeMenuItem, setActiveMenuItem] = useState(
    getActiveMenuItem(menu, location.pathname)
  );
  const [activeMenuGroup, setActiveMenuGroup] = useState(
    getActiveMenuGroup(menu, activeMenuItem)
  );

  useEffect(() => {
    const newActiveMenuItem = getActiveMenuItem(menu, location.pathname);
    const newActiveMenuGroup = getActiveMenuGroup(menu, newActiveMenuItem);
    setActiveMenuItem(newActiveMenuItem);
    setActiveMenuGroup(newActiveMenuGroup);
  }, [menu, location.pathname]);

  return (
    <Card className="topbar border-0 shadow">
      <Nav className="mx-3">
        {menu.map((group, index) => {
          const isActive = activeMenuGroup === group;
          const isLastGroup = index === menu.length - 1;
          const itemClassName = [
            "topbar-group",
            isActive ? "active rounded shadow-sm bg-white" : "text-muted",
            isLastGroup ? "ms-auto" : "",
          ].join(" ");
          const linkClassName = [
            "d-flex align-items-center",
            isActive ? "" : "text-reset",
          ].join(" ");
          // Each group contains at least one item
          const firstItem = group.items[0];

          return (
            <Nav.Item key={index} className={itemClassName}>
              <Nav.Link as={Link} className={linkClassName} {...firstItem.link}>
                <Icon icon={group.icon} className="me-2" />
                <span>{group.label}</span>
              </Nav.Link>
            </Nav.Item>
          );
        })}
      </Nav>
      <Card className="p-2 border-0 bg-white shadow-sm">
        <Nav variant="pills">
          {activeMenuGroup.items.map((item) => {
            const isActive = activeMenuItem === item;
            const itemClassName = [
              isActive ? "rounded shadow-sm" : "text-muted",
            ].join(" ");
            const linkClassName = [isActive ? "" : "text-reset"].join(" ");
            return (
              <Nav.Item key={item.link.route} className={itemClassName}>
                <Nav.Link as={Link} className={linkClassName} {...item.link}>
                  {item.label}
                </Nav.Link>
              </Nav.Item>
            );
          })}
        </Nav>
      </Card>
    </Card>
  );
};

export default Topbar;
