import React, { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import graphql from "babel-plugin-relay/macro";
import { useMutation } from "react-relay/hooks";
import Alert from "react-bootstrap/Alert";

import * as images from "assets/images";
import type { ClientAdd_addClient_Mutation } from "api/__generated__/ClientAdd_addClient_Mutation.graphql";
import { Route, useNavigate } from "Navigation";
import Button from "components/Button";
import ClientAddForm from "components/ClientAddForm";
import Image from "components/Image";
import Page from "components/Page";
import { SidebarContent } from "components/Sidebar";
import Spinner from "components/Spinner";
import Stack from "components/Stack";

const ADD_CLIENT_MUTATION = graphql`
  mutation ClientAdd_addClient_Mutation($input: AddClientInput!) {
    addClient(input: $input) {
      client {
        id
        name
      }
    }
  }
`;

const ClientAddSidebar = () => {
  return (
    <Stack gap={3} className="mt-3 p-3">
      <Image src={images.clients} />
    </Stack>
  );
};

const initialDraft = {
  referralCode: "",
};

const ClientAdd = () => {
  const [draft, setDraft] = useState({
    client: initialDraft,
    isValid: false,
  });
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const navigate = useNavigate();

  const [addClient, isAddingClient] = useMutation<ClientAdd_addClient_Mutation>(
    ADD_CLIENT_MUTATION
  );

  const handleAddClient = useCallback(() => {
    const { referralCode } = draft.client;
    addClient({
      variables: {
        input: { referralCode },
      },
      onCompleted(data, errors) {
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          return setErrorFeedback(errorFeedback);
        }
        const clientId = data.addClient?.client.id;
        if (clientId) {
          navigate({ route: Route.clientsEdit, params: { clientId } });
        } else {
          navigate({ route: Route.clients });
        }
      },
      onError(error) {
        setErrorFeedback(
          <FormattedMessage
            id="pages.ClientAdd.loadingErrorFeedback"
            defaultMessage="Could not add client, please try again."
            description="Feedback for unknown loading error in the ClientAdd page"
          />
        );
      },
      updater(store) {
        // TODO: should use and update Connections instead of invalidating the entire store
        // see https://relay.dev/docs/guided-tour/list-data/updating-connections/
        store.invalidateStore();
      },
    });
  }, [navigate, addClient, draft.client]);

  return (
    <Page
      title={
        <FormattedMessage
          id="pages.ClientAdd.title"
          defaultMessage="Add Client"
          description="Title for the ClientAdd page"
        />
      }
    >
      <Alert
        show={!!errorFeedback}
        variant="danger"
        onClose={() => setErrorFeedback(null)}
        dismissible
      >
        {errorFeedback}
      </Alert>
      <Stack gap={3}>
        <ClientAddForm
          value={draft.client}
          onChange={(client, isValid) => setDraft({ client, isValid })}
        />
        <div className="mt-3 d-flex justify-content-end align-items-center">
          <Button
            disabled={!draft.isValid || isAddingClient}
            onClick={handleAddClient}
          >
            {isAddingClient && <Spinner size="sm" className="me-2" />}
            <FormattedMessage
              id="pages.ClientAdd.addClientButton"
              defaultMessage="Add Client"
            />
          </Button>
        </div>
      </Stack>
      <SidebarContent>
        <ClientAddSidebar />
      </SidebarContent>
    </Page>
  );
};

export default ClientAdd;
