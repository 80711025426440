import React, { useCallback, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import Icon from "components/Icon";

type FormControlProps = React.ComponentProps<typeof Form.Control>;
type Props = Omit<FormControlProps, "type">;

const InputPassword = ({ children, className, ...restProps }: Props) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const togglePasswordVisibility = useCallback(() => {
    setIsPasswordVisible((v) => !v);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <InputGroup className={className}>
      <Form.Control
        ref={inputRef}
        className="border-end-0"
        type={isPasswordVisible ? "text" : "password"}
        {...restProps}
      />
      <InputGroup.Text
        role="button"
        className="bg-transparent"
        onClick={togglePasswordVisibility}
      >
        <Icon icon={isPasswordVisible ? "hide" : "show"} />
      </InputGroup.Text>
      {children}
    </InputGroup>
  );
};

export default InputPassword;
