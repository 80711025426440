import React, { createContext, useContext, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { fetchGraphQL } from "contexts/Relay";
import { useSession } from "contexts/Session";
import Spinner from "components/Spinner";

import defaultProperties from "./themes/default-properties.scss";
import defaultTheme from "./themes/default.scss";
import energyTheme from "./themes/energy.scss";
import fallTheme from "./themes/fall.scss";
import fashionTheme from "./themes/fashion.scss";
import nightTheme from "./themes/night.scss";
import noirTheme from "./themes/noir.scss";
import springTheme from "./themes/spring.scss";
import summerTheme from "./themes/summer.scss";

const themePresets = {
  default: defaultTheme,
  energy: energyTheme,
  fall: fallTheme,
  fashion: fashionTheme,
  night: nightTheme,
  noir: noirTheme,
  spring: springTheme,
  summer: summerTheme,
} as const;

type ThemePreset = keyof typeof themePresets;

const isThemePreset = (theme: string): theme is ThemePreset => {
  return themePresets.hasOwnProperty(theme);
};

const getThemeUrl = (theme: string | null) => {
  if (!theme) {
    return defaultTheme;
  }
  return isThemePreset(theme) ? themePresets[theme] : theme;
};

const tenantQuery = `
    query Tenant_getTenant_Query {
      tenant {
        authConfig {
          recaptchaSiteKey
        }
        design {
          logo
          theme
        }
        deviceClaimInsteadOfApplianceRegistration
        deviceClaimWithImplicitSerial
        deviceClaimWithTokenChallenge
      }
    }
  `;

type AuthConfig = {
  recaptchaSiteKey: string | null;
};

type Design = {
  theme: string | null;
  logo: string | null;
};

type TenantConfig = {
  authConfig: AuthConfig;
  design: Design;
  deviceClaimInsteadOfApplianceRegistration: boolean;
  deviceClaimWithImplicitSerial: boolean;
  deviceClaimWithTokenChallenge: boolean;
};

const fallbackConfig: TenantConfig = {
  authConfig: { recaptchaSiteKey: null },
  design: { theme: null, logo: null },
  deviceClaimInsteadOfApplianceRegistration: false,
  deviceClaimWithImplicitSerial: false,
  deviceClaimWithTokenChallenge: false,
};

const TenantConfigContext = createContext<TenantConfig | null>(fallbackConfig);

type TenantConfigProviderProps = {
  children: React.ReactNode;
};

const TenantConfigProvider = ({ children }: TenantConfigProviderProps) => {
  const { baseBackendUrl, tenantSlug } = useSession();
  const [tenantConfig, setTenantConfig] = useState<TenantConfig | null>(null);

  useEffect(() => {
    fetchGraphQL(tenantQuery, {}, null, baseBackendUrl, tenantSlug)
      .then((response) => {
        setTenantConfig(response.data.tenant);
      })
      .catch(() => {
        setTenantConfig(fallbackConfig);
      });
  }, [baseBackendUrl, tenantSlug]);

  if (!tenantConfig) {
    return (
      <div
        className="vh-100 d-flex justify-content-center align-items-center"
        data-testid="tenant-config-context-loading"
      >
        <Spinner />
      </div>
    );
  }

  const themeUrl = getThemeUrl(tenantConfig.design.theme);

  return (
    <HelmetProvider>
      <Helmet>
        <link href={defaultProperties} type="text/css" rel="stylesheet" />
        <link href={themeUrl} type="text/css" rel="stylesheet" />
      </Helmet>
      <TenantConfigContext.Provider value={tenantConfig}>
        {children}
      </TenantConfigContext.Provider>
    </HelmetProvider>
  );
};

const useTenantConfig = (): TenantConfig => {
  const contextValue = useContext(TenantConfigContext);
  if (contextValue == null) {
    throw new Error("TenantConfigContext has not been Provided");
  }
  return contextValue;
};

export { getThemeUrl, TenantConfigContext, useTenantConfig };

export default TenantConfigProvider;
