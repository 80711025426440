import Card from "react-bootstrap/Card";
import Nav from "react-bootstrap/Nav";
import _ from "lodash";

import ActionButton from "./ActionButton";

type Props<Item, ItemId = any> = {
  activeId?: ItemId;
  children: (item: Item) => React.ReactNode;
  className?: string;
  getItemId?: (item: Item) => ItemId;
  items: Item[];
  onChange?: (item: ItemId) => void;
};

function SegmentedControl<Item>({
  activeId,
  children,
  className = "",
  getItemId = (item: Item) => _.get(item, "id"),
  items,
  onChange = () => {},
}: Props<Item>) {
  return (
    <Card className={"p-2 border-0 " + className}>
      <Nav
        role="tablist"
        as="ul"
        variant="pills"
        className="nav-tabs d-flex align-items-center border-0"
      >
        {items.map((item, index) => {
          const itemId = getItemId(item);
          const isActive = itemId === activeId;
          return (
            <Nav.Item
              key={index}
              as="li"
              role="none presentation"
              className="me-2"
            >
              {isActive ? (
                <ActionButton onClick={() => onChange(itemId)}>
                  {children(item)}
                </ActionButton>
              ) : (
                <button
                  className="btn text-muted"
                  onClick={() => onChange(itemId)}
                >
                  {children(item)}
                </button>
              )}
            </Nav.Item>
          );
        })}
      </Nav>
    </Card>
  );
}

export default SegmentedControl;
