import { FormattedMessage } from "react-intl";

import { Link, Route } from "Navigation";
import StyledTable from "components/StyledTable";
import type { Column } from "components/StyledTable";
import type Organization from "api/Organization";

type Data = Pick<Organization, "id" | "name">;

const columns: Column<Data>[] = [
  {
    accessor: "name",
    Header: (
      <FormattedMessage
        id="components.ClientsTable.nameTitle"
        defaultMessage="Name"
        description="Title for the Name column of the clients table"
      />
    ),
    Cell: ({ row, value }) => (
      <Link route={Route.clientsEdit} params={{ clientId: row.original.id }}>
        {value}
      </Link>
    ),
  },
];

interface Props {
  className?: string;
  data: Data[];
  searchText?: string;
}

const ClientsTable = ({ className, data, searchText }: Props) => {
  return (
    <StyledTable
      className={className}
      columns={columns}
      data={data}
      searchText={searchText}
    />
  );
};

export default ClientsTable;
