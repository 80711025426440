import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import Button from "components/Button";
import Spinner from "components/Spinner";

interface UpgradeToFullOrganizationModalProps {
  defaultName: string;
  isConfirming?: boolean;
  onCancel: () => void;
  onConfirm: (organizationName: string) => void;
}

const UpgradeToFullOrganizationModal = ({
  defaultName,
  isConfirming = false,
  onCancel,
  onConfirm,
  ...restProps
}: UpgradeToFullOrganizationModalProps): React.ReactElement => {
  const [organizationName, setOrganizationName] = useState(defaultName);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) =>
      setOrganizationName(e.target.value),
    []
  );

  const canConfirm = !!organizationName && !isConfirming;

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === "Enter" && canConfirm) {
        onConfirm(organizationName);
      }
    },
    [canConfirm, onConfirm, organizationName]
  );

  const handleHide = useCallback(() => {
    onCancel && onCancel();
  }, [onCancel]);

  return (
    <div onKeyDown={handleKeyDown} {...restProps}>
      <Modal show centered size="lg" onHide={handleHide}>
        <Modal.Header closeButton>
          <Modal.Title>
            {
              <FormattedMessage
                id="components.UpgradeToFullOrganizationModal.title"
                defaultMessage="Upgrade to Full Organization"
                description="Title for the Upgrade to Full Organization modal in the Profile Page"
              />
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            <FormattedMessage
              id="components.UpgradeToFullOrganizationModal.prompt"
              defaultMessage="Upgrading your account to a full organization will allow you to manage resources such as the organization's users, roles, and clients. Choose your organization's name to proceed."
              description="Description of the action to confirm for the Upgrade to Full Organization modal"
            />
          </p>
          <Form.Group controlId="organizationName">
            <Form.Control
              type="text"
              value={organizationName}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {
            <Button variant="secondary" onClick={onCancel}>
              {
                <FormattedMessage
                  id="components.UpgradeToFullOrganizationModal.cancelButton"
                  defaultMessage="Cancel"
                  description="Title for the cancel button of the Upgrade to Full Organization modal in the Profile Page"
                />
              }
            </Button>
          }
          <Button
            variant="primary"
            disabled={!canConfirm}
            onClick={() => onConfirm(organizationName)}
          >
            {isConfirming && <Spinner className="me-2" size="sm" />}
            {
              <FormattedMessage
                id="components.UpgradeToFullOrganizationModal.confirmButton"
                defaultMessage="Upgrade"
                description="Title for the confirm button of the Upgrade to Full Organization modal in the Profile Page"
              />
            }
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UpgradeToFullOrganizationModal;
