import { useCallback, useState } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { yupResolver } from "@hookform/resolvers/yup";
import Form from "react-bootstrap/Form";

import ActionButton from "components/ActionButton";
import Button from "components/Button";
import Icon from "components/Icon";
import Spinner from "components/Spinner";
import Stack from "components/Stack";
import { messages, yup } from "forms";

const formSchema = yup
  .object({
    emails: yup
      .array()
      .required()
      .min(1)
      .of(
        yup
          .object({ value: yup.string().email().required() })
          .required()
          .test("unique", messages.unique.id, (email, context) => {
            const itemIndex = context.parent.indexOf(email);
            return !context.parent.find(
              (e: { value: string }, index: number) =>
                e.value === email.value && index < itemIndex
            );
          })
      ),
  })
  .required();

type FormData = {
  emails: { value: string }[];
};

const initialData: FormData = {
  emails: [],
};

type Props = {
  isLoading?: boolean;
  onSubmit: (emails: string[]) => void;
};

const InviteUsersForm = ({ isLoading, onSubmit }: Props) => {
  const [emailDraft, setEmailDraft] = useState("");
  const intl = useIntl();
  const {
    control,
    register,
    handleSubmit,
    trigger,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "onTouched",
    defaultValues: initialData,
    resolver: yupResolver(formSchema),
  });

  const emails = useFieldArray({
    control,
    name: "emails",
  });

  const onFormSubmit = (data: FormData) =>
    onSubmit(data.emails.map((email) => email.value));

  const handleAddEmail = useCallback(() => {
    emails.append({ value: emailDraft });
    trigger();
    setEmailDraft("");
  }, [emails, emailDraft, trigger]);

  const handleRemoveEmail = useCallback(
    (index: number) => {
      emails.remove(index);
      trigger();
    },
    [emails, trigger]
  );

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Form.Label>
        <FormattedMessage
          id="forms.InviteUsers.emailsLabel"
          defaultMessage="Emails"
        />
      </Form.Label>
      <Stack gap={3}>
        {emails.fields.map((email, index: number) => (
          <Stack key={email.id} gap={3} direction="horizontal">
            <Stack>
              <Form.Control
                className="border-bottom"
                {...register(`emails.${index}.value`)}
                isInvalid={!!errors.emails?.[index]}
                readOnly
                plaintext
              />
              <Form.Control.Feedback type="invalid">
                {errors.emails?.[index]?.value?.message && (
                  <FormattedMessage
                    id={errors.emails?.[index]?.value?.message}
                  />
                )}
              </Form.Control.Feedback>
            </Stack>
            <ActionButton
              className="mb-auto"
              onClick={() => handleRemoveEmail(index)}
            >
              <Icon icon="close" />
            </ActionButton>
          </Stack>
        ))}
        <Stack gap={3} direction="horizontal">
          <Form.Control
            type="email"
            value={emailDraft}
            onChange={(event) => setEmailDraft(event.target.value)}
            placeholder={intl.formatMessage({
              id: "forms.InviteUsers.emailPlaceholder",
              defaultMessage: "Type in the user email",
            })}
          />
          <ActionButton onClick={handleAddEmail}>
            <Icon icon="plus" />
          </ActionButton>
        </Stack>
        <div className="mt-3 d-flex justify-content-end align-items-center">
          <Button
            variant="primary"
            type="submit"
            disabled={!isValid || isLoading}
          >
            {isLoading && <Spinner size="sm" className="me-2" />}
            <FormattedMessage
              id="forms.InviteUsers.submitButton"
              defaultMessage="Invite Users"
            />
          </Button>
        </div>
      </Stack>
    </form>
  );
};

export default InviteUsersForm;
