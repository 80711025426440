/**
 * @generated SignedSource<<dd0d3bb9df43799e8408544c28645b13>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Users_getUsers_Query$variables = {};
export type Users_getUsers_Query$data = {
  readonly users: ReadonlyArray<{
    readonly id: string;
    readonly email: string;
    readonly name: string;
    readonly roles: ReadonlyArray<{
      readonly id: string;
      readonly name: string;
    }>;
  }>;
};
export type Users_getUsers_Query = {
  variables: Users_getUsers_Query$variables;
  response: Users_getUsers_Query$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "users",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Role",
        "kind": "LinkedField",
        "name": "roles",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Users_getUsers_Query",
    "selections": (v2/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Users_getUsers_Query",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "30c38a96d6f310d20613e276621ac5b2",
    "id": null,
    "metadata": {},
    "name": "Users_getUsers_Query",
    "operationKind": "query",
    "text": "query Users_getUsers_Query {\n  users {\n    id\n    email\n    name\n    roles {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f6257c19420845532b5175faa422ffc3";

export default node;
