import { ErrorBoundary } from "react-error-boundary";
import type { ErrorBoundaryPropsWithComponent } from "react-error-boundary";

import { Route, useNavigate } from "Navigation";
import { useViewer } from "contexts/Viewer";

type RelayError = Error & {
  source: any;
};

const isRelayError = (e: any): e is RelayError =>
  e.name === "RelayNetwork" && !!e.source;

const CleaErrorBoundary = ({
  children,
  onError,
  ...otherProps
}: React.PropsWithChildren<ErrorBoundaryPropsWithComponent>) => {
  const { setAcceptedLatestTermsAndConditions } = useViewer();
  const navigate = useNavigate();

  return (
    <ErrorBoundary
      onError={(error, info) => {
        if (isRelayError(error)) {
          const errors = error.source?.errors || [];
          const isTermsAndConditionsError = errors.find(
            (e: any) => e.code === "must_accept_latest_terms"
          );
          if (isTermsAndConditionsError) {
            setAcceptedLatestTermsAndConditions(false);
            navigate(Route.profile);
            return;
          }
        }
        if (onError) {
          onError(error, info);
        }
      }}
      {...otherProps}
    >
      {children}
    </ErrorBoundary>
  );
};

export default CleaErrorBoundary;
