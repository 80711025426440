import React, {
  createContext,
  useContext,
  useCallback,
  useMemo,
  useState,
  useEffect,
} from "react";
import axios from "axios";
import Cookies from "js-cookie";

import Spinner from "components/Spinner";

const getBaseBackendUrl = () => {
  const appMetatag: HTMLElement = document.head.querySelector(
    "[name=application-name]"
  )!;
  return new URL(appMetatag.dataset?.backendUrl || "http://localhost:3000");
};

const COOKIE_POLICY_VERSION = 1;

type AcceptedCookies = "All" | "StrictlyNecessary";

const isValidCookie = (val: string): val is AcceptedCookies =>
  val === "All" || val === "StrictlyNecessary";

const parseAcceptedCookies = (encodedValue: string): AcceptedCookies | null => {
  let preferences = null;
  try {
    preferences = JSON.parse(encodedValue);
  } catch {}

  if (!preferences) {
    return null;
  }

  if (!preferences?.version || preferences.version < COOKIE_POLICY_VERSION) {
    return null;
  }

  if (
    !preferences?.acceptedCookies ||
    !isValidCookie(preferences.acceptedCookies)
  ) {
    return null;
  }

  return preferences.acceptedCookies;
};

const loadCookiePreferences = (): AcceptedCookies | null => {
  let acceptedCookies = null;
  const encodedValue = Cookies.get("cookiePreferences") || null;
  if (encodedValue) {
    acceptedCookies = parseAcceptedCookies(encodedValue);
    if (!acceptedCookies) {
      saveCookiePreferences(null);
    }
  }
  return acceptedCookies;
};

const saveCookiePreferences = (acceptedCookies: AcceptedCookies | null) => {
  const cookieOptions = {
    secure: true,
    expires: 180, // about 6 months
  };

  if (acceptedCookies) {
    const cookiePreferences = JSON.stringify({
      acceptedCookies,
      version: COOKIE_POLICY_VERSION,
    });
    Cookies.set("cookiePreferences", cookiePreferences, cookieOptions);
  } else {
    Cookies.remove("cookiePreferences", cookieOptions);
  }
};

const loadRedirectTo = (): string | null => {
  return Cookies.get("redirectTo") || null;
};

const saveRedirectTo = (redirectTo: string | null) => {
  const cookieOptions = {
    secure: true,
    expires: 1, // 1 day
  };

  if (redirectTo) {
    Cookies.set("redirectTo", redirectTo, cookieOptions);
  } else {
    Cookies.remove("redirectTo", cookieOptions);
  }
};

const loadAuthToken = (): string | null => {
  return Cookies.get("authToken") || null;
};

const saveAuthToken = (token: string | null, persistAuth: boolean = false) => {
  // If expires is undefined, closing the browser/session will delete the cookie
  const cookieOptions = {
    secure: true,
    expires: persistAuth ? 365 : undefined,
  };

  if (token) {
    Cookies.set("authToken", token, cookieOptions);
  } else {
    Cookies.remove("authToken", cookieOptions);
  }
};

const fetchTenantSlug = async (domain: string): Promise<string | null> =>
  axios
    .get(new URL(`/domains/${domain}/info`, getBaseBackendUrl()).toString())
    .then((response) => response.data.data.tenant_slug)
    .catch((error) => {
      console.error(error);
      return null;
    });

type SessionContextValue = {
  authToken: string | null;
  baseBackendUrl: URL;
  cookiePreferences: AcceptedCookies | null;
  redirectTo: string | null;
  tenantSlug: string | null;
  setAuthToken: (token: string | null, persistAuth?: boolean) => void;
  setCookiePreferences: (cookiePreferences: AcceptedCookies | null) => void;
  setRedirectTo: (redirectTo: string | null) => void;
};

const SessionContext = createContext<SessionContextValue | null>(null);

type SessionProviderProps = {
  children: React.ReactNode;
};

const SessionProvider = ({ children }: SessionProviderProps) => {
  const [redirectTo, setRedirectTo] = useState(loadRedirectTo());
  const [authToken, setAuthToken] = useState(loadAuthToken());
  const [tenantSlug, setTenantSlug] = useState<string | null>(null);
  const [isFetchingTenant, setIsFetchingTenant] = useState(true);
  const baseBackendUrl = useMemo(() => getBaseBackendUrl(), []);
  const [
    cookiePreferences,
    setCookiePreferences,
  ] = useState<AcceptedCookies | null>(loadCookiePreferences());

  useEffect(() => {
    fetchTenantSlug(document.location.hostname)
      .then(setTenantSlug)
      .finally(() => setIsFetchingTenant(false));
  }, []);

  const handleSetCookiePreferences = useCallback((newCookiePreferences) => {
    saveCookiePreferences(newCookiePreferences);
    setCookiePreferences(newCookiePreferences);
  }, []);

  const handleSetToken = useCallback((newAuthToken, persistAuth) => {
    saveAuthToken(newAuthToken, persistAuth);
    setAuthToken(newAuthToken);
  }, []);

  const handleSetRedirectTo = useCallback((newRedirectTo) => {
    saveRedirectTo(newRedirectTo);
    setRedirectTo(newRedirectTo);
  }, []);

  const contextValue = useMemo(
    () => ({
      authToken,
      baseBackendUrl,
      cookiePreferences,
      redirectTo,
      tenantSlug,
      setCookiePreferences: handleSetCookiePreferences,
      setAuthToken: handleSetToken,
      setRedirectTo: handleSetRedirectTo,
    }),
    [
      authToken,
      baseBackendUrl,
      cookiePreferences,
      redirectTo,
      tenantSlug,
      handleSetCookiePreferences,
      handleSetToken,
      handleSetRedirectTo,
    ]
  );

  if (isFetchingTenant) {
    return (
      <div
        className="vh-100 d-flex justify-content-center align-items-center"
        data-testid="tenant-slug-loading"
      >
        <Spinner />
      </div>
    );
  }

  if (!tenantSlug) {
    // TODO move the language provider up in the context list
    return (
      <div>
        <h3>Configuration error.</h3>
        <p>Please verify your tenant configuration in the Clea Admin panel.</p>
      </div>
    );
  }

  return (
    <SessionContext.Provider value={contextValue}>
      {children}
    </SessionContext.Provider>
  );
};

const useSession = () => {
  const contextValue = useContext(SessionContext);
  if (contextValue == null) {
    throw new Error("SessionContext has not been Provided");
  }
  return contextValue;
};

export { SessionContext, useSession };
export type { AcceptedCookies, SessionContextValue };

export default SessionProvider;
