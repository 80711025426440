import { createContext, useContext, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import Card from "react-bootstrap/Card";

import * as images from "assets/images";
import { useTenantConfig } from "contexts/TenantConfig";
import "./Sidebar.scss";

type SidebarContextValue = {
  sidebarContentNode: HTMLDivElement | null;
  setSidebarContentNode: (node: HTMLDivElement | null) => void;
};

const SidebarContext = createContext<SidebarContextValue>({
  sidebarContentNode: null,
  setSidebarContentNode: () => {},
});

const SidebarProvider = ({ children }: { children: React.ReactNode }) => {
  const [
    sidebarContentNode,
    setSidebarContentNode,
  ] = useState<HTMLDivElement | null>(null);
  const contextValue = useMemo(
    () => ({ sidebarContentNode, setSidebarContentNode }),
    [sidebarContentNode, setSidebarContentNode]
  );
  return (
    <SidebarContext.Provider value={contextValue}>
      {children}
    </SidebarContext.Provider>
  );
};

const Sidebar = () => {
  const tenantConfig = useTenantConfig();
  const { setSidebarContentNode } = useContext(SidebarContext);
  const brandImg = tenantConfig.design.logo || images.brand;

  return (
    <Card className="flex-grow-1 sidebar border-0 shadow d-flex flex-column">
      <img
        alt="Logo"
        src={brandImg}
        className="sidebar-brand mw-100 mx-auto my-3 py-2"
      />
      <div ref={setSidebarContentNode} className="flex-grow-1" />
    </Card>
  );
};

const SidebarContent = ({ children }: { children: React.ReactNode }) => {
  const { sidebarContentNode } = useContext(SidebarContext);
  if (!sidebarContentNode) {
    return null;
  }
  return createPortal(children, sidebarContentNode);
};

export { SidebarContent, SidebarProvider };

export default Sidebar;
