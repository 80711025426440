import React from "react";
import Badge from "react-bootstrap/Badge";

type BadgeProps = React.ComponentProps<typeof Badge>;

type Props = Omit<BadgeProps, "pill" | "variant">;

const Tag = ({ children, className, ...restProps }: Props) => {
  return (
    <Badge
      {...restProps}
      pill
      bg="primary"
      className={`py-1 px-3 ${className}`}
    >
      {children}
    </Badge>
  );
};

export default Tag;
