type Props = {
  count: number;
  resource: React.ReactNode;
};

const ResourceCounter = ({ count, resource }: Props) => {
  return (
    <div className="text-center">
      <div className="h1 mb-0">{count}</div>
      <div>{resource}</div>
    </div>
  );
};

export default ResourceCounter;
