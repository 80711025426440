import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useMutation } from "react-relay/hooks";
import graphql from "babel-plugin-relay/macro";
import type { UserInvite_inviteUsers_Mutation } from "api/__generated__/UserInvite_inviteUsers_Mutation.graphql";
import Alert from "react-bootstrap/Alert";

import * as images from "assets/images";
import { Route, useNavigate } from "Navigation";
import Image from "components/Image";
import Page from "components/Page";
import { SidebarContent } from "components/Sidebar";
import Stack from "components/Stack";
import InviteUsersForm from "forms/InviteUsers";

const INVITE_USERS_MUTATION = graphql`
  mutation UserInvite_inviteUsers_Mutation($input: InviteUsersInput!) {
    inviteUsers(input: $input) {
      emails
    }
  }
`;

const UserInviteSidebar = () => {
  return (
    <Stack gap={3} className="mt-3 p-3">
      <Image src={images.users} />
    </Stack>
  );
};

const UserInvite = () => {
  const navigate = useNavigate();
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const [
    inviteUsers,
    isInvitingUsers,
  ] = useMutation<UserInvite_inviteUsers_Mutation>(INVITE_USERS_MUTATION);

  const handleUserInvite = (emails: string[]) => {
    inviteUsers({
      variables: { input: { emails } },
      onCompleted(data, errors) {
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          return setErrorFeedback(errorFeedback);
        }
        navigate({ route: Route.users });
      },
      onError(error) {
        setErrorFeedback(
          <FormattedMessage
            id="pages.UserInvite.inviteUsersErrorFeedback"
            defaultMessage="Could not invite users, please try again."
            description="Feedback for unknown invite user error in the UserInvite page"
          />
        );
      },
      updater(store) {
        // TODO: should use and update Connections instead of invalidating the entire store
        // see https://relay.dev/docs/guided-tour/list-data/updating-connections/
        store.invalidateStore();
      },
    });
  };

  return (
    <Page
      title={
        <FormattedMessage
          id="pages.UserInvite.title"
          defaultMessage="Invite Users"
          description="Title for the UserInvite page"
        />
      }
    >
      <Alert
        show={!!errorFeedback}
        variant="danger"
        onClose={() => setErrorFeedback(null)}
        dismissible
      >
        {errorFeedback}
      </Alert>
      <InviteUsersForm
        onSubmit={handleUserInvite}
        isLoading={isInvitingUsers}
      />
      <SidebarContent>
        <UserInviteSidebar />
      </SidebarContent>
    </Page>
  );
};

export default UserInvite;
