import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faArrowDown,
  faArrowUp,
  faCircle,
  faEllipsisV,
  faEye,
  faEyeSlash,
  faGlobeAmericas,
  faLanguage,
  faPaste,
  faPlus,
  faSearch,
  faTabletAlt,
  faTimes,
  faUser,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";

const icons = {
  appliances: faTabletAlt,
  arrowDown: faArrowDown,
  arrowUp: faArrowUp,
  caretDown: faAngleDown,
  caretUp: faAngleUp,
  circle: faCircle,
  close: faTimes,
  clients: faGlobeAmericas,
  copyPaste: faPaste,
  hide: faEyeSlash,
  plus: faPlus,
  language: faLanguage,
  moreOptionsMenu: faEllipsisV,
  profile: faUser,
  search: faSearch,
  show: faEye,
  users: faUserFriends,
} as const;

type FontAwesomeIconProps = React.ComponentProps<typeof FontAwesomeIcon>;

type Props = Omit<FontAwesomeIconProps, "icon"> & {
  icon: keyof typeof icons;
};

const Icon = ({ icon, ...restProps }: Props) => {
  return <FontAwesomeIcon {...restProps} icon={icons[icon]} />;
};

export default Icon;
