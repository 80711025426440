import { createPortal } from "react-dom";
import { FormattedMessage } from "react-intl";
import Card from "react-bootstrap/Card";

import Button from "components/Button";
import Center from "components/Center";
import Result from "components/Result";
import Spinner from "components/Spinner";
import "./Page.scss";

type PageHeaderProps = {
  title?: React.ReactNode;
};

const PageHeader = ({ title }: PageHeaderProps) => {
  return (
    <header className="d-flex justify-content-between align-items-center">
      {title && (
        <h2 className="mb-4" data-testid="page-title">
          {title}
        </h2>
      )}
      <div className="mb-4" id="page-toolbar" />
    </header>
  );
};

type PageToolbarProps = {
  children?: React.ReactNode;
};

const PageToolbar = ({ children }: PageToolbarProps) => {
  const node = document.getElementById("page-toolbar");
  if (!node) {
    return null;
  }
  // TODO: using a Portal is the React-supported way to render a component into
  // a DOM node that exists outside the DOM hierarchy of the parent component.
  // However, this escapes the simple top-down approach of React, so it should
  // be avoided if possible.
  // We're using a Portal here since we have the following structure:
  // Page with PageHeader > Suspense > PageToolbar (in the OK Loaded state)
  // We could avoid using a Portal by lifting up the state, but that implies
  // wrapping Page with Suspense which will result in annoying flickering when
  // changing between the Loading and OK Loaded states.
  return createPortal(children, node);
};

type PageMainProps = {
  children?: React.ReactNode;
};

const PageMain = ({ children }: PageMainProps) => {
  return <main>{children}</main>;
};

const PageLoading = () => {
  return (
    <Center>
      <Spinner data-testid="page-loading" />
    </Center>
  );
};

type PageLoadingErrorProps = {
  onRetry?: () => void;
};

const PageLoadingError = ({ onRetry }: PageLoadingErrorProps) => {
  return (
    <Center>
      <Result.Error
        data-testid="page-error"
        title={
          <FormattedMessage
            id="components.Page.loadingError.feedback"
            defaultMessage="The page couldn't load."
          />
        }
      >
        {onRetry && (
          <Button onClick={onRetry} className="mt-3 m-auto">
            <FormattedMessage
              id="components.Page.loadingError.retryButton"
              defaultMessage="Try Again"
            />
          </Button>
        )}
      </Result.Error>
    </Center>
  );
};

type PageProps = {
  children?: React.ReactNode;
  title?: React.ReactNode;
};

const Page = ({ children, title }: PageProps) => {
  return (
    <Card data-testid="page" className="flex-grow-1 p-4 border-0 shadow">
      <PageHeader title={title} />
      <PageMain>{children}</PageMain>
    </Card>
  );
};

export { PageLoading, PageLoadingError, PageToolbar };

export default Page;
