/**
 * @generated SignedSource<<031c519e2cce2aabd478d4d83a426fb9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateUserRolesInput = {
  roleIds: ReadonlyArray<string>;
  userId: string;
};
export type User_updateUserRoles_Mutation$variables = {
  input: UpdateUserRolesInput;
};
export type User_updateUserRoles_Mutation$data = {
  readonly updateUserRoles: {
    readonly user: {
      readonly id: string;
      readonly roles: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
      }>;
    };
  } | null;
};
export type User_updateUserRoles_Mutation = {
  variables: User_updateUserRoles_Mutation$variables;
  response: User_updateUserRoles_Mutation$data;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateUserRolesPayload",
    "kind": "LinkedField",
    "name": "updateUserRoles",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Role",
            "kind": "LinkedField",
            "name": "roles",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "User_updateUserRoles_Mutation",
    "selections": (v2/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "User_updateUserRoles_Mutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "5c8fd6a67ee3a6ba2bd0dc2439e8478a",
    "id": null,
    "metadata": {},
    "name": "User_updateUserRoles_Mutation",
    "operationKind": "mutation",
    "text": "mutation User_updateUserRoles_Mutation(\n  $input: UpdateUserRolesInput!\n) {\n  updateUserRoles(input: $input) {\n    user {\n      id\n      roles {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "22055cff6d11cbe7da9f194b4e4d0208";

export default node;
